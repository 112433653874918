import {
  FormUserAnswer,
  Page,
  PageElement,
  ProjectReducer,
  UserAnswerAnswer,
  type ProjectStage,
} from '@app/@types/redux/project.js';
import { PAGE_ELEMENT, ROLES } from '@libs/constants/constants';
import { Helmet } from 'react-helmet';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useForm,
} from 'react-hook-form';
import { FiArrowLeft, FiArrowRight, FiCheck, FiHome, FiMenu } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import { View } from 'react-native-web';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';
import Progress from 'reactstrap/lib/Progress';
import hold from '../../../assets/images/icons/diamond.png';
import smile from '../../../assets/images/icons/smile.svg';
import { TITLE } from '../../../libs/constants/constants.ts';
import { redirectToChallengePage, slugify, stripHtml } from '../../../libs/helper';
import i18n, { i18nCText } from '../../../libs/i18n/I18n';
import {
  canSubmit,
  getCurrentPage,
  getCurrentProject,
  getFeedback,
  getPageElementAnswerAndType,
  getPageElementById,
  getWorkingStage,
  isLastPage as isLastPageFn,
} from '../../../state/selectors/projects';
import { getCurrentUser, getUserGrowths } from '../../../state/selectors/users';
import { checkAnyPermission } from '../../../state/selectors/workspace';
import Api from '../../../state/utils/Api';
import {
  Alert,
  Animated,
  Card,
  CardBody,
  // CardBody,
  CardHeader,
  Col,
  Container,
  Div,
  H1,
  H2,
  H3,
  H4,
  ImagePlaceholder,
  Indicator,
  Row,
  SubmitButton,
} from '../../UI/Html';

import env from '@config/env.ts';
import logger from '@libs/log.ts';
import {
  answerProject,
  fetchProject,
  fetchReviewStatus,
  fetchUserProject,
  hideNotAnsweredModal,
  nextValidate,
  resetEvaluationOrProject,
  setAllowCopyPaste,
  submitAnswers,
  switchProjectPage,
  switchProjectStage,
  updateRestrictions,
} from '@state/redux/slices/project.slice.ts';
import { fetchNotifications } from '@state/redux/slices/user.slice.ts';
import { fetchCurrentWorkspace, verifyUserWorkspace } from '@state/redux/slices/workspace.slice.ts';
import { AppDispatch, RootState, store, useAppDispatch } from '@state/redux/store.ts';
import { translate } from '@state/utils/helper.ts';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Footer from '../../UI/Layout/Footer';
import ProjectHeader from '../ProjectHeader.tsx';
import NotificationModal from '../ProjectNotificationModal';
import {
  Button as ButtonE,
  CardFeedback,
  Cards,
  Checkboxes,
  Essay,
  FillTheBlank,
  IdeaCards,
  Image,
  ImageRadioButtons,
  Input,
  Message,
  MessageReview,
  Others,
  PersonaEntry,
  PostIt,
  PostItCheckBoxes,
  RadioButtons,
  SubmissionCard,
  TextArea,
  Video,
} from './Elements';
import CustomerJourneyMap from './Elements/CustomerJourneyMap';

import PrioritizationMatrix from './Elements/PrioritizationMatrix';
import { Video as VideoPage } from './Pages';
import CountDown from './Pages/CountDown';
import ModelCanvas, { ExternalPageElementProps } from './Pages/ModelCanvas';
// import Quiz from './Pages/Quiz';
import type { OthersOptions } from '@app/@types/redux/pageElement.js';
import Chat from '@components/Chat/index.tsx';
import EmailActivity from '@components/Email/EmailActivity.tsx';
import ExecutiveSummary from '@components/ExecutiveSummary/index.tsx';
import Audio from './Elements/Audio.tsx';
import Quiz from './Pages/Quiz.tsx';
import Sidebar from './Sidebar/Sidebar';
import './TakeProject.scss';
export interface PageElementProps {
  answer: (payload: UserAnswerAnswer) => void;
  position?: number;
  pageElement: PageElement;
  currentAnswer?: UserAnswerAnswer;
  getDependeeAnswerType: (peId: string) => {
    answer: UserAnswerAnswer;
    type: PAGE_ELEMENT;
  };
  getPageElement?: (peId: string) => PageElement | undefined;
  nextValidate?: (nextButtonValidate: boolean) => void;
  form?: {
    register: UseFormRegister<FormUserAnswer>;
    errors: FieldErrors<FormUserAnswer>;
    trigger: UseFormTrigger<FormUserAnswer>;
    watch: UseFormWatch<FormUserAnswer>;
    setValue: UseFormSetValue<FormUserAnswer>;
    control: Control<FormUserAnswer>;
    getValues: UseFormGetValues<FormUserAnswer>;
  };
  state: ProjectReducer;
}

interface CombinedProps extends PageElementProps, ExternalPageElementProps { }

const inputsElements: Record<PAGE_ELEMENT, React.FC<CombinedProps> | null> = {
  [PAGE_ELEMENT.ADD_ENTRY]: null,
  [PAGE_ELEMENT.AI_RESPONSE]: null,
  [PAGE_ELEMENT.AUTO_DRAW]: null,
  [PAGE_ELEMENT.BUSINESS_MODEL_CANVAS]: null,
  [PAGE_ELEMENT.BUTTON]: ButtonE,
  [PAGE_ELEMENT.CANVAS]: ModelCanvas,
  [PAGE_ELEMENT.CARD_FEEDBACK]: CardFeedback,
  [PAGE_ELEMENT.CARD_FINAL_FEEDBACK]: null,
  [PAGE_ELEMENT.CARD_FINAL_REVIEW]: null,
  [PAGE_ELEMENT.CARD_REVIEW]: null,
  [PAGE_ELEMENT.CARD]: Cards,
  [PAGE_ELEMENT.CHECKBOX]: Checkboxes,
  [PAGE_ELEMENT.COLUMN_TEXTAREA]: null,
  [PAGE_ELEMENT.CUSTOMER_JOURNEY_MAP]: CustomerJourneyMap,
  [PAGE_ELEMENT.DOWNLOAD_FILE]: null,
  [PAGE_ELEMENT.DROPDOWN]: null,
  [PAGE_ELEMENT.ESSAY]: Essay,
  [PAGE_ELEMENT.EXAMPLE]: null,
  [PAGE_ELEMENT.FILL]: FillTheBlank,
  [PAGE_ELEMENT.FINAL_CARD]: null,
  [PAGE_ELEMENT.FINAL_FEEDBACK]: null,
  [PAGE_ELEMENT.FINAL_REVIEW]: null,
  [PAGE_ELEMENT.FIVE_WHYS]: null,
  [PAGE_ELEMENT.HMW_RANDOM]: null,
  [PAGE_ELEMENT.IDEA_CARD]: IdeaCards,
  [PAGE_ELEMENT.IMAGE_CHECKBOX]: null,
  [PAGE_ELEMENT.IMAGE_MESSAGE]: null,
  [PAGE_ELEMENT.IMAGE_RADIO]: ImageRadioButtons,
  [PAGE_ELEMENT.IMAGE_URL]: null,
  [PAGE_ELEMENT.IMAGE]: Image,
  [PAGE_ELEMENT.INPUT_LIST]: null,
  [PAGE_ELEMENT.INPUT]: Input,
  [PAGE_ELEMENT.MARVEL_IFRAME]: null,
  [PAGE_ELEMENT.MESSAGE_REVIEW]: MessageReview,
  [PAGE_ELEMENT.MESSAGE]: Message,
  [PAGE_ELEMENT.NULL]: null,
  [PAGE_ELEMENT.OTHERS]: Others,
  [PAGE_ELEMENT.PERSONA_ENTRY]: PersonaEntry,
  [PAGE_ELEMENT.POSTIT_CHECKBOX]: PostItCheckBoxes,
  [PAGE_ELEMENT.POSTIT]: PostIt,
  [PAGE_ELEMENT.PRIORITIZATION_MATRIX]: PrioritizationMatrix,
  [PAGE_ELEMENT.RADIO]: RadioButtons,
  [PAGE_ELEMENT.SPACING]: null,
  [PAGE_ELEMENT.SUBMISSION_CARD]: SubmissionCard,
  [PAGE_ELEMENT.TEXTAREA]: TextArea,
  [PAGE_ELEMENT.UPLOAD_FILE]: null,
  [PAGE_ELEMENT.UPLOAD_IMAGE]: null,
  [PAGE_ELEMENT.USER_SELECT]: null,
  [PAGE_ELEMENT.VIDEO]: Video,
  [PAGE_ELEMENT.AUDIO]: Audio,
  [PAGE_ELEMENT.EXECUTIVE_SUMMARY]: ExecutiveSummary,
  [PAGE_ELEMENT.EMAIL_ACTIVITY]: EmailActivity,
  [PAGE_ELEMENT.CHAT_ACTIVITY]: Chat,
};

const mapStateToProps = (state: RootState) => ({
  getAnswerType: (peId: string) => getPageElementAnswerAndType(state.projects, peId),
  getPageElement: (peId: string) => getPageElementById(state.projects, peId),
  project: getCurrentProject(state.projects),
  currentUser: getCurrentUser(state),
  currentStage: getWorkingStage(state.projects),
  currentPage: getCurrentPage(state.projects),
  growths: getUserGrowths(state),
  isLastPage: isLastPageFn(state.projects),
  nextButtonValidate: state.projects.nextButtonValidate,
  userAnswers: state.projects.answers,
  currentStageId: state.projects.activeStageId,
  currentPageId: state.projects.currentPageId,
  restrictions: state.projects.restrictions,
  currentActualStageId: state.projects.currentStageId,
  notAnsweredModal: state.projects.notAnsweredModal,
  feedbacks: getFeedback(state.projects),
  reviewStatus: state.projects.currentProject.reviewStatus,
  userProject: state.projects.currentProject.userProject,
  currentWorkspace: state.workspaces.currentWorkspace,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetEvaluationOrProject: (entityType: string) => dispatch(resetEvaluationOrProject(entityType)),
  submit: (
    showPageLoader: () => void,
    hidePageLoader: (stage?: ProjectStage, page?: Page) => void
  ) => dispatch(submitAnswers({ showPageLoader, hidePageLoader })),
  switchProjectPage: (id: string) => {
    dispatch(switchProjectPage(id));
  },
  answerProject: (peId: string, type: PAGE_ELEMENT, payload: UserAnswerAnswer) =>
    dispatch(
      answerProject({
        peId,
        type,
        payload,
      })
    ),
  nextValidate: (nextButtonValidate: boolean) => dispatch(nextValidate(nextButtonValidate)),
  hideNotAnsweredModal: () => {
    dispatch(hideNotAnsweredModal());
  },
  dispatchAllowPaste: (allowCopyPaste: boolean) => dispatch(setAllowCopyPaste(allowCopyPaste)),
});

function loadingSkeleton() {
  return (
    <Col xs={12} className="m5-5">
      <Skeleton count={5} height={20} />
    </Col>
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TakeProjectProps = PropsFromRedux;

const TakeProject: React.FC<TakeProjectProps> = (props) => {
  const { workspaceId, projectId, stageId, pageId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (workspaceId) {
      verifyUserWorkspace(workspaceId)
        .then((res) => {
          if (
            checkAnyPermission(res.data.data.attributes.userWorkspaceRoles, [
              ROLES.TESTER,
              ROLES.ADMIN,
              ROLES.OWNER,
            ])
          ) {
            dispatch(setAllowCopyPaste(true));
          }
          if (
            !checkAnyPermission(res.data.data.attributes.userWorkspaceRoles, [
              ROLES.TESTER,
              ROLES.ACCESS_APPROVED,
            ])
          ) {
            navigate(`/workspace/${workspaceId}`);
          }
        })
        .catch(() => {
          navigate(`/workspace/${workspaceId}`);
        });
    }
  }, [workspaceId]);

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormUserAnswer>({
    mode: 'onChange',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [sidebarOpenFlag, setSidebarOpenFlag] = useState(false);
  const [beforeSubmitModal, setBeforeSubmitModal] = useState(false);
  const [notEnoughReviews, setNotEnoughReviews] = useState(false);
  const [feedbackReviewModal, setFeedbackReviewModal] = useState(false);
  const [feedbackReviewLeftElement, setFeedbackReviewLeftElement] = useState({
    cardFeedbackId: '',
    leftToReview: 0,
    pageId: '',
    stageId: '',
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      dispatch(fetchProject({ id: projectId }))
        .unwrap()
        .then((res) => {
          if (res.data.attributes.workspaceId)
            dispatch(fetchCurrentWorkspace(res.data.attributes.workspaceId)).catch((e: unknown) => {
              logger.error(e);
            });
          dispatch(resetEvaluationOrProject('project'));
          setIsLoading(false);
          dispatch(fetchNotifications()).catch((e: unknown) => {
            logger.error(e);
          });
          dispatch(fetchReviewStatus({ projectId })).catch((e: unknown) => {
            logger.error(e);
          });
          dispatch(fetchUserProject({ id: projectId }))
            .then(() => {
              dispatch(updateRestrictions());
            })
            .catch((e: unknown) => {
              logger.error(e);
              setIsLoading(false);
            });
          dispatch(updateRestrictions());
          if (stageId && pageId) {
            dispatch(switchProjectStage({ id: stageId, pageId }));
          }
        })
        .catch((e: unknown) => {
          logger.error(e);
          setIsLoading(false);
        });
    }
    return () => {
      document.body.classList.remove('g-sidenav-hidden');
    };
  }, [projectId, stageId, pageId]);

  if (!projectId || !workspaceId) {
    return (
      <View className="px-3 text-center">
        <i className="py-2 far fa-frown-open emptyPageIcon" />
        <H2>{translate('takeProject.noProjectError')}</H2>
      </View>
    );
  }

  function toggleSidebar() {
    const challengeSidenav = document.getElementById('challengeSidenav');
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      if (challengeSidenav) {
        challengeSidenav.classList.add('sidenav-closed');
        challengeSidenav.classList.remove('sidenav-open');
      }
      setSidebarOpenFlag(true);
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      if (challengeSidenav) {
        challengeSidenav.classList.add('sidenav-open');
        challengeSidenav.classList.remove('sidenav-closed');
      }
      setSidebarOpenFlag(false);
    }
  }

  function countDownEndCallback() {
    dispatch(fetchProject({ id: String(projectId) }))
      .then(() => {
        dispatch(fetchUserProject({ id: String(projectId) }))
          .then(() => {
            dispatch(updateRestrictions());
          })
          .catch((e: unknown) => {
            logger.error(e);
          });
      })
      .catch((e: unknown) => {
        logger.error(e);
      });
  }

  function isInLastPage() {
    const { isLastPage } = props;
    return isLastPage;
  }
  const { getPageElement, answerProject, nextValidate, getAnswerType } = props;

  function processPageElements(pageElements: PageElement[]) {
    return (
      <>
        {pageElements.map((pe, index) => {
          const IElement = inputsElements[pe.attributes.elementType];
          if (!IElement) return null;
          return (
            <Animated key={pe.id}>
              <IElement
                key={pe.id}
                position={index}
                pageElement={pe}
                currentAnswer={getAnswerType(pe.id)?.answer ?? undefined}
                answer={(payload) => {
                  answerProject(pe.id, pe.attributes.elementType, payload);
                }}
                getDependeeAnswerType={
                  getAnswerType as (peId: string) => {
                    answer: UserAnswerAnswer;
                    type: PAGE_ELEMENT;
                  }
                }
                getPageElement={getPageElement}
                nextValidate={(nextButtonValidate) => {
                  nextValidate(nextButtonValidate);
                }}
                state={store.getState().projects}
                form={{
                  register,
                  errors,
                  trigger,
                  watch,
                  setValue,
                  control,
                  getValues,
                }}
              />
            </Animated>
          );
        })}
      </>
    );
  }

  function beforeSubmitAlert() {
    setBeforeSubmitModal(true);
  }

  function renderPage() {
    const { currentPage, currentStage, project } = props;
    const stage = currentStage?.attributes;
    const page = currentPage?.attributes;
    if (!page) return null;
    if (!stage) return null;
    const pageElements = page.pageElements.map((pe) => ({
      ...pe,
      ...pe.attributes,
    }));
    let defaultPageContent;
    let dateObj = Date.parse(String(stage.beginDate));
    dateObj += 1800000;
    const varDate = new Date(dateObj);
    const tempPageElements = pageElements.filter((pe) => pe.elementType !== PAGE_ELEMENT.EXAMPLE);
    if (stage.id && varDate.getDate() && new Date(varDate) > new Date()) {
      return (
        <>
          <Helmet>
            <title>{`${i18nCText(page.title)} | ${i18nCText(project.attributes.name)}`}</title>
          </Helmet>
          <CountDown beginDate={varDate} callback={countDownEndCallback} />
        </>
      );
    }
    if (stage.id && stage.pages.length === 0) {
      return (
        <>
          <Helmet>
            <title>{`${i18nCText(page.title)} | ${i18nCText(project.attributes.name)}`}</title>
          </Helmet>
          <View className="px-3 text-center">
            <i className="py-2 far fa-frown-open emptyPageIcon" />
            <H2>{translate('takeProject.noPageError')}</H2>
          </View>
        </>
      );
    }
    // if (page.pageType === 'Chat') {
    //   defaultPageContent = (
    //     <ChatWindow
    //       currentStage={currentStage}
    //       pageElements={pageElements}
    //       answerProject={answerProject}
    //       getAnswerType={getAnswerType}
    //       currentPage={currentPage}
    //     />
    //   );
    // }
    if (page.pageType === 'Video') {
      defaultPageContent = (
        <div>
          {page.title && <H3 className="takeProject__pageHeading">{i18nCText(page.title)}</H3>}
          <VideoPage pageElements={pageElements} />
        </div>
      );
    }
    if (page.pageType === 'Other') {
      switch (page.options.type) {
        case 'quiz': {
          defaultPageContent = <Quiz page={page} answerProject={answerProject} />;
          break;
        }
        // case 'example': {
        //   defaultPageContent = <ExamplePage page={page} />;
        //   break;
        // }
        // case 'ai_instructions': {
        //   defaultPageContent = (
        //     <AIInstructions
        //       page={page}
        //       getDependeeAnswerType={getAnswerType}
        //       answerProject={answerProject}
        //     />
        //   );
        //   break;
        // }
        // case 'ai_job_recommendations': {
        //   defaultPageContent = (
        //     <JobRecommendations
        //       page={page}
        //       getDependeeAnswerType={getAnswerType}
        //       answerProject={answerProject}
        //     />
        //   );
        //   break;
        // }
        default:
          defaultPageContent = null;
      }
    }
    if (defaultPageContent) {
      return (
        <>
          <Helmet>
            <title>{`${i18nCText(page.title)} | ${i18nCText(project.attributes.name)}`}</title>
          </Helmet>
          <Animated>
            {/* {page.title && <H3 className="takeProject__pageHeading">{i18nCText(page.title)}</H3>} */}
            {defaultPageContent}
            {/* {examplePageElements.length > 0 && (
              <AISidePane
                key={page.id}
                options={page.options.example}
                pageElements={examplePageElements}
                getDependeeAnswerType={getAnswerType}
              />
            )} */}
          </Animated>
        </>
      );
    }

    return (
      <>
        <Helmet>
          <title>{`${i18nCText(page.title)} | ${i18nCText(project.attributes.name)}`}</title>
        </Helmet>
        <Animated>
          {/* {page.title && <H3 className="takeProject__pageHeading">{i18nCText(page.title)}</H3>} */}
          <Row>
            <Col md={12}>{processPageElements(tempPageElements)}</Col>
            {/* {examplePageElements.length > 0 && (
              <AISidePane
                key={page.id}
                options={page.options.example}
                pageElements={examplePageElements}
                getDependeeAnswerType={getAnswerType}
              />
            )} */}
          </Row>
        </Animated>
      </>
    );
  }

  function renderStage() {
    const { currentPage, userProject } = props;
    const page = currentPage?.attributes;
    if (!page) return null;
    // page.pageElements[0].attributes.elementType
    const isChatPage = Boolean(
      page.pageElements.find((pe) => pe.attributes.elementType === PAGE_ELEMENT.CHAT_ACTIVITY)?.id ??
      (page.pageElements.find((pe) => (pe.attributes.elementType === PAGE_ELEMENT.OTHERS))?.attributes.options as OthersOptions | undefined)?.type === 'chat'
    );
    return (
      <Card>
        <CardHeader className="takeProject__pageCardHeader">
          {/* This should be the current stage + page name */}
          <div>
            <H4 className="takeProject__pageName">{i18nCText(page.title)}</H4>
          </div>
          <div className="d-flex align-items-center" id="projectHeaderProgressTooltip">
            <Progress
              max="1"
              value={userProject.attributes.progress || 0}
              color="info"
              className="takeProject__challengeProgress"
            />

            {userProject.attributes.progress === 1 ? (
              <i className="ml-2 fas fa-check-circle text-green" />
            ) : null}
          </div>
          <UncontrolledTooltip delay={0} placement="top" target="projectHeaderProgressTooltip">
            {translate('takeProject.challengeProgress', {
              progress: userProject.attributes.progress * 100,
            })}
          </UncontrolledTooltip>
          {/* <ProjectPagination className='float-right' /> */}
        </CardHeader>
        <CardBody
          className={classNames(
            isChatPage ? 'takeProject__cardBody__noPadding' : 'takeProject__cardBody__withPadding'
          )}
        >
          {isLoading ? loadingSkeleton() : renderPage()}
        </CardBody>
      </Card>
    );
  }

  const { isLastPage, submit, switchProjectPage, project, currentStage, currentPage, userProject } =
    props;
  const { name } = project.attributes;
  const stage = currentStage;
  const page = currentPage;
  if (!stage || !page) return null;
  let previousPage: Page | null = null;
  let nextPage: Page | null = null;
  const cpId = parseInt(page.id, 10);
  const pages = stage.attributes.pages;

  pages.forEach((p, index) => {
    const pId = parseInt(p.id, 10);
    if (cpId === pId) {
      previousPage = pages[index - 1];
      if (!isInLastPage()) {
        nextPage = pages[index + 1];
      }
    }
  });

  const previousPageFn = () => {
    if (previousPage) {
      redirectToChallengePage(project, stage, previousPage, workspaceId);
      switchProjectPage(previousPage.id);
    }
  };

  const nextPageFn = () => {
    if (nextPage) {
      redirectToChallengePage(project, stage, nextPage, workspaceId);
      switchProjectPage(nextPage.id);
    }
  };

  /**
   * This function is used to check if the user has given valid answers till the current page and if the user has received or given all the feedbacks or reviews
   * @returns {boolean} - Returns true if the user has given valid answers till the current page, else false
   */
  const beforeNextStageValidation = () => {
    // Check if the user has given valid answers till the current page
    if (canSubmit(store.getState().projects)) {
      // if the current stage is feedback stage, then we need to check if the user has received or given the desired number of feedbacks or reviews
      if (stage.attributes.stageType.includes('Feedback')) {
        // Get the review status of the project
        const { reviewStatus } = props;
        const tReviewStatus = reviewStatus;

        // Get the card feedbacks of the current page
        const cardFeedbacks = stage.attributes.pages[0]?.attributes?.pageElements?.filter(
          (pe) => pe.attributes.elementType === PAGE_ELEMENT.CARD_FEEDBACK
        );

        // Check if the user has received or given the desired number of feedbacks or reviews
        const hasExpertAiSelfReview = cardFeedbacks.some((cardFeedback) => {
          // This is the review status of the card feedback in the form of an object
          const reviewsDone = tReviewStatus[cardFeedback.attributes.dependees[0]?.dependeeId];

          // check if the review type for the stage is expertReview or aiReview or selfReview and not peerReview
          if (
            (reviewsDone.selectedReviewTypes.includes('expertReview') ||
              reviewsDone.selectedReviewTypes.includes('aiReview') ||
              reviewsDone.selectedReviewTypes.includes('selfReview')) &&
            !reviewsDone.selectedReviewTypes.includes('peerReview')
          ) {
            // if yes, then let user move forward
            return true;
          }
          // check if the review type for the stage is peerReview and the user has given the desired number of reviews
          if (
            reviewsDone.selectedReviewTypes.includes('peerReview') &&
            reviewsDone.reviewsDone >= env.MINIMUM_GIVE_REVIEWS
          ) {
            // if yes, then let user move forward
            return true;
          }
          // If no condition is satisfied, then we set the feedback page element and show the not enough reviews modal
          // setFeedbackPageElementId(cardFeedback.attributes.dependees[0]?.dependeeId);
          setNotEnoughReviews(true);
        });

        // Check if the user has received or given the desired number of feedbacks or reviews
        if (hasExpertAiSelfReview) {
          // If the user has received or given the desired number of feedbacks or reviews, then we can proceed to the next stage
          return true;
        }
      } else {
        // If the current stage is not a feedback stage, then we can proceed to the next stage
        return true;
      }
    }
  };

  /**
   * This function is used to check if the user has given valid answers till the current page
   * @returns {boolean} - Returns true if the user has given valid answers till the current page, else false
   * */
  const nextStageFn = () => {
    const { currentActualStageId, restrictions } = props;
    // Checking if the user has given valid answers till the current page or any feedback or review is left or not. Check beforeNextStageValidation() function for more details
    if (beforeNextStageValidation()) {
      // If the user has given valid answers, then we can proceed to the next stage. If the active stage is not the current stage, just move the user to the next stage
      if (
        stage.id !== currentActualStageId &&
        restrictions.find((restriction) => restriction.stageId === stage.id)?.allow === true
      ) {
        // Move to the next stage
        submit(
          () => undefined,
          (stage?: ProjectStage, page?: Page) => {
            if (stage && page) {
              redirectToChallengePage(project, stage, page, workspaceId);
            }
          }
        ).catch((e: unknown) => {
          logger.error(e);
        });
        return;
      }
      // If the active stage is the current stage, then we can show the alert of before submit modal
      beforeSubmitAlert();
      return;
    }
    // If the user has not given valid answers, then we show the toast of not answered and return nothing
    return '';
  };

  /**
   * This function is executed on the click of the finish challenge button
   * @returns {string} - Returns the string of the function to be executed when the user clicks on the finish challenge button
   */
  const finishChallengeFn = () => {
    // Checking if the user has given valid answers till the current page or any feedback or review is left or not. Check beforeNextStageValidation() function for more details
    if (beforeNextStageValidation()) {
      // If the user has given valid answers, then we can proceed to finish challenge. Before that we need to check if the user has reviewed all the feedbacks or not
      Api.getFeedbackStatus(projectId)
        .then((res) => {
          const data = res.data;
          // If the user has not reviewed all the feedbacks, then we show the alert of feedback left to review modal
          // Iterate over the feedbacks and check if the user has any feedback left to review
          for (const d of data) {
            // If the user has feedback left to review, ie. leftToReview > 0, then we show the feedback left to review modal or else we show the alert of before submit modal. -1 is the value when the user has not received any feedback
            if (d.leftToReview > 0 && d.leftToReview !== -1) {
              // If the user has feedback left to review, then we set the feedback left to review element and show the feedback left to review modal
              setFeedbackReviewLeftElement(d);
              setFeedbackReviewModal(true);
              return;
            }
            // If the user has not received any feedback, then we show the alert of before submit modal
            beforeSubmitAlert();
            return;
          }

          // If the user has reviewed all the feedbacks or there is no value in data ie, there is no feedback to review, then we show the alert of before submit modal
          beforeSubmitAlert();
        })
        .catch((e: unknown) => {
          logger.error(e);
        });
    }
    // If the user has not given valid answers, then we show the toast of not answered and return nothing
    return '';
  };

  const title = `${i18nCText(project.attributes.name)} | ${TITLE}`;
  const { shortDescription } = project.attributes;
  const challengeImage =
    project.attributes.options.images[i18n.language] || project.attributes.imageData;

  function onSubmitFn() {
    nextPageFn();
  }

  let description: string = shortDescription;
  try {
    description = stripHtml(i18nCText(shortDescription));
  } catch (e) {
    description = shortDescription;
  }
  const beforeSubmitModalTitle =
    currentStage.attributes.options.creationOptions?.settings?.preSubmitModal?.popupTitle ??
    translate('takeProject.popups.beforeSubmitModal.heading');
  const beforeSubmitModalDescription =
    currentStage.attributes.options.creationOptions?.settings?.preSubmitModal?.popupDescription ??
    translate('takeProject.popups.beforeSubmitModal.description');

  return (
    <Container fluid className="px-0" style={{ overflow: 'hidden' }}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="og:image" content={challengeImage} />
        <meta property="twitter:image" content={challengeImage} />
      </Helmet>
      <div
        style={sidebarOpenFlag ? { display: 'none' } : {}}
        className="backdrop d-xl-none"
        role="button"
        tabIndex={0}
        onClick={() => {
          toggleSidebar();
        }}
      >
        &nbsp;
      </div>
      {/* Notification modal starts here */}
      <NotificationModal
        project={project}
        redirect={(sectionId) => {
          navigate(
            `/workspace/${workspaceId}/challenge/${project.id}/${slugify(
              project.attributes.name
            )}/${sectionId || ''}`
          );
        }}
        showAllComplete={false}
        showDoneButIncomplete={false}
        workspaceId={workspaceId}
      />
      {/* Notification modal ends here */}

      {/* This is the before submit modal */}
      <Alert
        isOpen={beforeSubmitModal}
        onRequestClose={() => {
          setBeforeSubmitModal(false);
        }}
        showCloseButton
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={smile} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">{beforeSubmitModalTitle}</H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-3">
          <Col>
            <p className="px-3 font-weight-400 text-dark">{beforeSubmitModalDescription}</p>
          </Col>
        </Row>
        <Row>
          {pageLoader ? (
            <Col>
              <Indicator />
            </Col>
          ) : (
            <>
              <Col className="px-1 mx-0 mb-2 text-center text-sm-right">
                <Button
                  color="grey"
                  onClick={() => {
                    setBeforeSubmitModal(false);
                  }}
                >
                  {translate('buttonLabels.changeAnswers')}
                </Button>
              </Col>
              <Col className="px-1 mx-0 mb-2 text-center text-sm-left">
                <Button
                  color="info"
                  onClick={() => {
                    submit(
                      () => {
                        setPageLoader(true);
                      },
                      (stage?: ProjectStage, page?: Page) => {
                        setPageLoader(false);
                        setBeforeSubmitModal(false);
                        if (stage && page) {
                          redirectToChallengePage(project, stage, page, workspaceId);
                        }
                      }
                    ).catch((e: unknown) => {
                      logger.error(e);
                    });
                  }}
                >
                  {translate('buttonLabels.submitAnswers')}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Alert>

      {/* This is the feedback left to review modal */}
      <Alert
        isOpen={feedbackReviewModal}
        onRequestClose={() => {
          setFeedbackReviewModal(false);
        }}
        showCloseButton
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={smile} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">You have feedback to review!</H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-3">
          <Col>
            <p className="px-3 font-weight-400 text-dark">
              Please review the peer-feedback received on your submission before proceeding further
            </p>
          </Col>
        </Row>
        <Row>
          {pageLoader ? (
            <Col>
              <Indicator />
            </Col>
          ) : (
            <>
              <Col className="px-1 mx-0 mb-2 text-center text-sm-right">
                <Button
                  color="grey"
                  onClick={() => {
                    submit(
                      () => {
                        setPageLoader(true);
                      },
                      (stage?: ProjectStage, page?: Page) => {
                        setPageLoader(false);
                        setBeforeSubmitModal(false);
                        setFeedbackReviewModal(false);
                        if (stage && page) {
                          redirectToChallengePage(project, stage, page, workspaceId);
                        }
                      }
                    ).catch((e: unknown) => {
                      logger.error(e);
                    });
                  }}
                >
                  Skip
                </Button>
              </Col>
              <Col className="px-1 mx-0 mb-2 text-center text-sm-left">
                <Button
                  color="info"
                  onClick={() => {
                    setFeedbackReviewModal(false);
                    dispatch(
                      switchProjectStage({
                        id: feedbackReviewLeftElement.stageId,
                        pageId: feedbackReviewLeftElement.pageId,
                      })
                    );
                    window.scrollTo(0, document.body.scrollHeight);
                  }}
                >
                  Review
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Alert>

      {/* This is not enough feedback modal */}
      <Alert
        isOpen={notEnoughReviews}
        onRequestClose={() => {
          setNotEnoughReviews(false);
        }}
        showCloseButton
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={hold} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">
              {translate('takeProject.popups.notEnoughFeedbacks.heading')}
            </H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-3">
          <Col>
            <p className="px-3 font-weight-400 text-dark">
              {translate('takeProject.popups.notEnoughFeedbacks.description')}
            </p>
          </Col>
        </Row>
        <Row>
          {pageLoader ? (
            <Col>
              <Indicator />
            </Col>
          ) : (
            <Col className="px-1 mx-0 mb-2 text-sm">
              <Button
                color="info"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {translate('takeProject.popups.notEnoughFeedbacks.waitReviewBtn')}
              </Button>
            </Col>
          )}
        </Row>
      </Alert>

      <Row className="mx-0">
        <Sidebar project={project} workspaceId={workspaceId} />
        <Col
          className="flex-grow px-0 d-flex flex-column main-content"
          style={{ overflow: 'hidden' }}
        >
          <ProjectHeader
            title={i18nCText(name)}
            subtitle={
              i18nCText(currentStage.attributes.stageName) ||
              translate(`projectStages.${currentStage.attributes.stageType}`)
            }
            pageId={currentPage.id ? currentPage.id : ''}
            toggleSidebar={toggleSidebar}
            currentStage={currentStage}
            projectOptions={project.attributes.options}
            userProject={userProject}
            workspaceId={workspaceId}
            pageType="projectPage"
          />
          <Row className="mx-0 justify-content-center mt--3">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={
                {
                  // paddingRight: '100px'
                }
              }
            >
              <form noValidate onSubmit={handleSubmit(onSubmitFn) as React.FormEventHandler}>
                <div
                  className="main-container"
                  style={{
                    minHeight: 'calc( 100vh - 315px )',
                  }}
                >
                  {renderStage()}
                  <div className="takeProject__navButtons">
                    <Button
                      color="secondary"
                      className={classNames({
                        disabled: !previousPage,
                        btnPrevious: true,
                      })}
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        previousPageFn();
                      }}
                    >
                      {translate('takeProject.previous')}
                    </Button>
                    {currentPage.id &&
                      Object.keys(currentPage).length !== 0 &&
                      !(
                        stage.attributes.beginDate &&
                        new Date(stage.attributes.beginDate) > new Date()
                      ) ? (
                      isLastPage ? (
                        <SubmitButton
                          color="danger"
                          loading={pageLoader}
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            finishChallengeFn();
                          }}
                        >
                          {translate('takeProject.finishChallenge')}
                        </SubmitButton>
                      ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        nextPage ? (
                          <Button type="submit">{translate('takeProject.next')}</Button>
                        ) : (
                          <SubmitButton
                            color="primary"
                            // disabled={nextButtonValidate}
                            id="popover-submit-stage"
                            loading={pageLoader}
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              nextStageFn();
                            }}
                          >
                            {translate('takeProject.submit')}
                          </SubmitButton>
                        )
                    ) : (
                      <br />
                    )}
                  </div>
                </div>
              </form>
              <Footer />
              <Div className="my-4 d-md-none" />
              <Div className="bottom-take-project-menu d-sm-block d-md-none">
                <Row className="p-0 m-0">
                  <Col
                    onClick={() => {
                      navigate(
                        `/workspace/${workspaceId}/challenge/${project.id}/${slugify(
                          project.attributes.name
                        )}/`
                      );
                    }}
                  >
                    <FiHome />
                  </Col>
                  <Col
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      previousPageFn();
                    }}
                  >
                    <FiArrowLeft />
                  </Col>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    currentPage.id &&
                      Object.keys(currentPage).length !== 0 &&
                      !(
                        stage.attributes.beginDate &&
                        new Date(stage.attributes.beginDate) > new Date()
                      ) ? (
                      // eslint-disable-next-line no-nested-ternary
                      isLastPage ? (
                        <Col
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                            finishChallengeFn();
                          }}
                        >
                          {pageLoader ? <Indicator /> : <FiCheck />}
                        </Col>
                      ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        nextPage ? (
                          <Col
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              nextPageFn();
                            }}
                          >
                            <FiArrowRight />
                          </Col>
                        ) : (
                          <Col
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              nextStageFn();
                            }}
                          >
                            {pageLoader ? <Indicator size="20" /> : <FiCheck />}
                          </Col>
                        )
                    ) : (
                      ''
                    )
                  }
                  <Col
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      toggleSidebar();
                    }}
                  >
                    <FiMenu />
                  </Col>
                </Row>
              </Div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default connector(TakeProject);
