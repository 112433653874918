import { Page, Project, ProjectStage } from '@app/@types/redux/project';
import { switchProjectStage } from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { AiFillAudio } from 'react-icons/ai';
import { HiVideoCamera } from 'react-icons/hi';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink, Navbar, NavbarBrand } from 'reactstrap';
import { redirectToChallengePage, redirectToChallengePageUrl } from '../../../../libs/helper';
import i18n, { i18nCText } from '../../../../libs/i18n/I18n';
import {
  getCurrentPage,
  getCurrentStage,
  getWorkingStage,
} from '../../../../state/selectors/projects';
import { translate } from '../../../../state/utils/helper';
import './Sidebar.scss';

interface SidebarProps {
  project: Project;
  workspaceId: string;
}

function Sidebar(props: SidebarProps) {
  const currentStageId = useAppSelector((state) => getCurrentStage(state.projects)?.id);
  const workingStageId = useAppSelector((state) => getWorkingStage(state.projects)?.id);
  const currentPageId = useAppSelector((state) => getCurrentPage(state.projects)?.id);
  const { workspaceId } = props;
  const restrictions = useAppSelector((state) => state.projects.restrictions);
  const [openedCollapses, setOpenedCollapses] = useState([workingStageId]);
  const dispatch = useAppDispatch();
  const isRTL = i18n.dir() === 'rtl';
  useEffect(() => {
    setOpenedCollapses([workingStageId]);
  }, [workingStageId]);

  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  };

  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  };

  const toggleCollapse = (stageId: string) => {
    if (openedCollapses.includes(stageId)) {
      setOpenedCollapses([]);
    } else {
      setOpenedCollapses([stageId]);
    }
  };

  const projectState = useAppSelector(
    (state) => state.projects.currentProject.userProject.attributes.state
  );

  const createPageLinks = (stage: ProjectStage, page: Page) => {
    const isSelected = Boolean(parseInt(page.id, 10) === parseInt(String(currentPageId), 10));
    let icon;
    switch (page.attributes.pageType) {
      case 'Video':
        icon = 'far fa-play-circle';
        break;
      case 'Introduction':
        icon = 'fas fa-book';
        break;
      default:
        icon = 'fas fa-pencil-alt';
    }
    const iconClassName = page.attributes.options.icon ?? icon;
    let iconComponent: JSX.Element
    switch (iconClassName) {
      case 'video':
        iconComponent = <i><HiVideoCamera size={20} /></i>;
        break;
      case 'audio':
        iconComponent = <i><AiFillAudio size={20} /></i>;
        break;
      default:
        iconComponent = <i className={iconClassName} />;
    }
    let completed = parseInt(stage.id, 10) !== parseInt(String(currentStageId), 10);
    if (projectState === 'Finished') {
      completed = true;
    }
    if (completed) {
      iconComponent = <i className="fas fa-check-circle text-green" />;
    }
    return (
      <NavItem
        className={classnames({
          active: isSelected,
        })}
        key={page.id}
      >
        <NavLink
          className={classnames(
            parseInt(String(currentPageId), 10) === parseInt(page.id, 10) ? 'text-info' : ''
          )}
          to={redirectToChallengePageUrl(props.project, stage, page, workspaceId)}
          tag={NavLinkRRD}
          onClick={(e: { preventDefault: () => void }) => {
            e.preventDefault();
            dispatch(switchProjectStage({ id: stage.id, pageId: page.id }));
            redirectToChallengePage(props.project, stage, page, workspaceId);
          }}
        >
          {iconComponent}
          <span className="nav-link-text">{i18nCText(page.attributes.title)}</span>
        </NavLink>
      </NavItem>
    );
  };

  const createStageLinks = (stage: ProjectStage) => {
    const isOpen = openedCollapses.includes(stage.id);
    const isAccessible = Boolean(
      restrictions.find((restriction) => Number(restriction.stageId) === Number(stage.id))?.allow
    );
    return (
      <NavItem key={stage.id}>
        <NavLink
          href="#pablo"
          style={isAccessible ? {} : { pointerEvents: 'none', opacity: '0.7' }}
          data-toggle="collapse"
          aria-expanded={isOpen}
          className={classnames({
            active: isOpen,
          })}
          onClick={(e: { preventDefault: () => void }) => {
            e.preventDefault();
            toggleCollapse(stage.id);
          }}
        >
          <i className={`ni ${stage.attributes.icon}`} />
          <span className="nav-link-text">
            {i18nCText(stage.attributes.stageName) ||
              translate(`projectStages.${stage.attributes.stageType}`)}
          </span>
        </NavLink>
        <Collapse isOpen={isOpen}>
          <Nav className="nav-sm flex-column sidebar__pageNavbar">
            {stage.attributes.pages.map((page: Page) => createPageLinks(stage, page))}
          </Nav>
        </Collapse>
      </NavItem>
    );
  };

  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {props.project.attributes.imageData ? (
          <NavbarBrand className="sidebar__navbarBrand">
            <img
              alt={props.project.attributes.name}
              className="sidebar__navbarBrandImage"
              src={
                props.project.attributes.options.images[i18n.language] ||
                props.project.attributes.options.images.en ||
                props.project.attributes.imageData
              }
            />
          </NavbarBrand>
        ) : null}
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen className="sidebar__collapseNavbar">
          <Nav navbar className="sidebar__stageNavbar">
            {props.project.attributes.projectStages.map((stage) => createStageLinks(stage))}
          </Nav>
        </Collapse>
      </div>
    </div>
  );
  useEffect(() => {
    document.body.classList.add('g-sidenav-pinned');
    document.getElementById('challengeSidenav')?.classList.add('sidenav-open');
  }, []);
  return (
    <Navbar
      className={classnames([
        'sidebar__navbar',
        'sidenav',
        'navbar-vertical',
        'navbar-expand-xs',
        'navbar-light',
        'bg-white',
        isRTL ? 'fixed-right' : 'fixed-left',
      ])}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
      id="challengeSidenav"
    >
      <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
    </Navbar>
  );
}

export default Sidebar;
