/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import classnames from 'classnames';

import { UserProject } from '@app/@types/redux/project';
import { ROLES } from '@libs/constants/constants';
import logger from '@libs/log';
import { toggleLoginModal, updateLocation } from '@state/redux/slices/auth.slice';
import { applyToProject, fetchProject, fetchUserProject } from '@state/redux/slices/project.slice';
import { fetchCurrentWorkspace, verifyUserWorkspace } from '@state/redux/slices/workspace.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { isLoggedIn } from '@utils/helpers/auth';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaCube, FaInfoCircle, FaSignInAlt } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, UncontrolledTooltip } from 'reactstrap';
import { TITLE } from '../../../libs/constants/constants';
import { stripHtml } from '../../../libs/helper';
import i18n, { i18nCText } from '../../../libs/i18n/I18n';
import { getCurrentPage, getProjectStatus } from '../../../state/selectors/projects';
import { checkAnyPermission } from '../../../state/selectors/workspace';
import { translate } from '../../../state/utils/helper';
import { CardBody, Col, Div, Nav, NavItem, NavLink, Row } from '../../UI/Html';
// import NoStyleDiv from '../../UI/Html/NoStyleDiv';
import Layout from '../../UI/Layout';
import Footer from '../../UI/Layout/Footer';
import ProjectNotificationModal from '../ProjectNotificationModal';
import Message, { ScenarioOverview } from '../TakeProject/Elements/Scenario';
import WorkspaceOwnerCard from '../WorkspaceOwnerCard';
import './index.scss';

export interface TopicWizardOverviewContentProps {
  assessmentCriterion: { keyLearningOutcome: string }[];
  workplaceScenario: {
    simulationType: string;
    overview: string;
    summary: string;
    background: {
      aboutOrganization: string;
      organizationName: string;
    };
    challenge: {
      currentIssue: string;
    };
    learnerRoleReportingManager: {
      learnerRole: {
        roleDescription: string;
        scopeOfWork: {
          task: string;
          description: string;
        }[];
        role: string;
      };
      reportingManager: {
        name: string;
        email: string;
        role: string;
        gender: string;
        message: string;
        avatarUrl: string;
      };
    };
  };
  simulationImage: string;
}

function ProjectPreview() {
  const isSignedIn = isLoggedIn();
  const { projectId, workspaceId } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (workspaceId) {
      if (isSignedIn) {
        verifyUserWorkspace(workspaceId)
          .then((res) => {
            if (
              !checkAnyPermission(res.data.data.attributes.userWorkspaceRoles, [
                ROLES.TESTER,
                ROLES.ACCESS_APPROVED,
              ])
            ) {
              navigate(`/workspace/${workspaceId}`);
            }
          })
          .catch(() => {
            dispatch(updateLocation(location));
            navigate('/');
          });
      } else {
        dispatch(updateLocation(location));
        navigate('/', { state: { from: location.pathname } });
      }
    }
  }, [workspaceId, isSignedIn, location]);
  const [searchParams] = useSearchParams();
  const section = searchParams.get('section');
  const token = searchParams.get('token');
  const tabContainer = useRef<HTMLDivElement>(null);
  const [projectIsFetching, setProjectIsFetching] = useState(true);
  const [userProjectIsFetching, setUserProjectIsFetching] = useState(true);
  const [applying, setApplying] = useState(false);
  function scrollToTop() {
    if (tabContainer.current) {
      const top = (tabContainer.current as HTMLElement).offsetTop - 12 || 0;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    }
  }
  const currentUser = useAppSelector((state) => state.users.user);
  const project = useAppSelector((state) => state.projects.previewProject);
  const userProject = useAppSelector((state) => state.projects.currentProject.userProject);
  const { overviewContent } = project.attributes.options;
  const currentPageId = useAppSelector((state) => getCurrentPage(state.projects)?.id);
  const currentStageId = useAppSelector((state) => state.projects.currentStageId);
  const [tab, setTab] = useState<string>(overviewContent?.[0]?.id ?? '');
  const currentWorkspace = useAppSelector((state) => state.workspaces.currentWorkspace);
  const topicWizardOverviewContent = project.attributes.options.creationOptions
    .topicWizardData as unknown as TopicWizardOverviewContentProps;

  useEffect(() => {
    if (projectId) {
      setProjectIsFetching(true);
      workspaceId &&
        dispatch(fetchCurrentWorkspace(workspaceId)).catch(() => {
          logger.error('Error fetching workspace');
        });
      dispatch(fetchProject({ id: projectId }))
        .then(() => {
          if (isSignedIn && currentUser.id) {
            dispatch(fetchUserProject({ id: projectId }))
              .then(() => {
                setUserProjectIsFetching(false);
              })
              .catch(() => {
                logger.error('Error fetching user project');
              });
          } else {
            setUserProjectIsFetching(false);
          }
          setProjectIsFetching(false);
        })
        .catch(() => {
          logger.error('Error fetching project');
        });
    }
  }, [currentUser, isSignedIn, projectId]);

  useEffect(() => {
    if (
      overviewContent &&
      overviewContent.length > 0 &&
      !overviewContent.find((item) => item.id === tab)
    ) {
      setTab(overviewContent[0].id);
    }
  }, [projectId, overviewContent]);

  const navigateTab = (pTab: string) => {
    setTab(pTab);
    scrollToTop();
  };

  const title = `${project.attributes.name} | ${TITLE}`;
  const { shortDescription } = project.attributes;
  let description = shortDescription;
  try {
    description = stripHtml(i18nCText(shortDescription));
  } catch (e) {
    description = shortDescription;
  }
  const isFetching = projectIsFetching || userProjectIsFetching;
  const status = useAppSelector((state) => getProjectStatus(state.projects));
  const applyButton = () => {
    const text = translate(`projectPreview.${status}`);
    const isDisabled =
      status === 'filled' || status === 'soon' || (status === 'finished' && !userProject);

    const buttonBody = (val: string) => (
      <CardBody className="apply-button-body">
        <Row className="mobile-button-safe-area">
          <div className="col">
            <h1 className="pt-2 text-white display-3 apply-button-text">{val}</h1>
          </div>
          <Col className="col-auto">
            <div className="bg-white shadow icon icon-shape text-dark rounded-circle">
              <i className="ni ni-spaceship" />
            </div>
          </Col>
        </Row>
      </CardBody>
    );

    if (!isSignedIn) {
      return (
        <Card
          onClick={() => {
            dispatch(updateLocation(location));
            dispatch(toggleLoginModal(true));
          }}
          className="border-0 cursor-pointer apply-button"
          tag="h5"
        >
          {buttonBody(translate('projectPreview.apply'))}
        </Card>
      );
    }
    return (
      <Card
        style={isDisabled ? { cursor: 'default' } : {}}
        onClick={() => {
          setApplying(true);
          if (isDisabled || applying) {
            setApplying(false);
            return undefined;
          }

          if (status === 'apply') {
            if (projectId)
              dispatch(applyToProject({ projectId, token }))
                .then(() => {
                  setApplying(false);
                })
                .catch(() => {
                  setApplying(false);
                });
          }
          if (status === 'goto' || status === 'finished') {
            if (!workspaceId || !projectId || !currentStageId || !currentPageId) {
              setApplying(false);
              return undefined;
            }
            const tempUrl = `${String(projectId)}/${currentStageId}/${String(
              currentPageId
            )}/created`;
            navigate(`/workspace/${String(workspaceId)}/challenge/${tempUrl}`);
            return;
          }
          setApplying(false);
          return undefined;
        }}
        className="border-0 cursor-pointer apply-button"
        tag="h5"
      >
        {buttonBody(applying ? '...' : text)}
      </Card>
    );
  };
  const submissionDeadline = moment(project.attributes.submissionDeadline);
  const startDate = moment(project.attributes.beginDate);
  let challengeImage = project.attributes.imageData;
  if (project.attributes.options.images[i18n.language]) {
    const languageImage = project.attributes.options.images[i18n.language];
    challengeImage = languageImage ? languageImage : project.attributes.options.images.en;
  }

  if (!projectId || !workspaceId) {
    return null;
  }

  const challengeName = i18nCText(project.attributes.name);
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="og:image" content={challengeImage} />
        <meta property="twitter:image" content={challengeImage} />
      </Helmet>
      {section && workspaceId && !['ideas', 'submissions'].includes(section) && isSignedIn && (
        <ProjectNotificationModal
          workspaceId={workspaceId}
          project={project}
          redirect={(sectionId: string) => {
            navigateTab(sectionId || 'overview');
          }}
        />
      )}
      <Div className="px-md-3 pt-md-3 view-challenge">
        <Row className="mt-3 mb-4">
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            {isFetching ? (
              <div
                style={{
                  marginTop: '-4px',
                }}
              >
                <Skeleton height="45vw" />
              </div>
            ) : (
              <Card className="workspace__view_imageCard">
                <img
                  className="rounded project-preview-card-img"
                  alt={challengeName}
                  src={challengeImage}
                />
              </Card>
            )}
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Card style={{ height: '100%' }}>
              <CardBody>
                {isFetching ? (
                  <Skeleton count={3} />
                ) : (
                  <div className="fadeInUp">
                    <div className="head-description">
                      <h1 style={{ fontSize: '2em', fontWeight: '600' }}>{challengeName}</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: i18nCText(project.attributes.description),
                        }}
                      />
                      {/* <NoStyleDiv>{i18nCText(parseMDX(project.attributes.description))}</NoStyleDiv> */}
                      <br />
                      <div className="timeline-container">
                        {startDate.isValid() ? (
                          <div>
                            <h2>{translate('projectPreview.startDate')}:</h2>
                            <h1>{startDate.format('MMM, Do YYYY, h:mm a')}</h1>
                          </div>
                        ) : null}
                        {submissionDeadline.isValid() ? (
                          <div>
                            <h2>{translate('projectPreview.submissionDeadline')}:</h2>
                            <h1>{submissionDeadline.format('MMM, Do YYYY, h:mm a')}</h1>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
            {currentWorkspace.attributes.options.owner ? (
              <WorkspaceOwnerCard
                loading={isFetching}
                name={currentWorkspace.attributes.options.owner}
                image={currentWorkspace.attributes.options.ownerImage}
              />
            ) : null}
            <RightSideContent
              isFetching={isFetching}
              userProject={userProject}
              workspaceId={workspaceId}
              projectId={projectId}
              currentStageId={currentStageId}
              currentPageId={currentPageId}
              applyButton={applyButton}
            />
          </Col>
        </Row>
        {overviewContent && overviewContent.length > 0 ? (
          <div ref={tabContainer}>
            <Row>
              <Col md={12} lg={12} xl={12}>
                <div className="p-0 nav-wrapper">
                  <Nav
                    className="nav-fill overviewPageNav"
                    id="tabs-icons-text"
                    pills
                    role="tablist"
                  >
                    {overviewContent.map((navItem) => {
                      const NavIcon = navItem.type === 'board' ? FaCube : FaInfoCircle;
                      if (
                        navItem.type === 'board' ||
                        !navItem.editor
                        // userProject?.state !== 'Finished'
                      )
                        return null;
                      return (
                        <NavItem style={{ marginBottom: '1rem' }} key={navItem.id}>
                          <NavLink
                            aria-selected={tab === navItem.id}
                            className={classnames('mb-sm-3 mb-md-0 font-weight-600', {
                              active: tab === navItem.id,
                            })}
                            style={{
                              fontSize: '1rem',
                            }}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                              e.preventDefault();
                              navigateTab(navItem.id);
                            }}
                            href="#"
                            role="tab"
                          >
                            <NavIcon className="mr-2" />
                            {i18nCText(navItem.name)}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                </div>
                {overviewContent.map((tabPane) => {
                  if (!tabPane.editor) {
                    return null;
                  }
                  if (tabPane.id === tab || tabPane.type === 'overview') {
                    return (
                      <>
                        {isFetching ? (
                          <Card className="rounded">
                            <CardBody>
                              <Skeleton count={3} />
                            </CardBody>
                          </Card>
                        ) : (
                          <>
                            {tab === tabPane.id &&
                              (tabPane.type === 'overview' || tabPane.type === 'tab') &&
                              tabPane.editor && (
                                <div className="fadeInUp">
                                  {overviewContent ? (
                                    <Message htmlContent={tabPane.editor} />
                                  ) : topicWizardOverviewContent?.workplaceScenario ? (
                                    <ScenarioOverview
                                      scenarioData={{
                                        learningOutcomes:
                                          topicWizardOverviewContent?.assessmentCriterion,
                                        simulationType:
                                          topicWizardOverviewContent?.workplaceScenario
                                            ?.simulationType ?? '',
                                        overview:
                                          topicWizardOverviewContent?.workplaceScenario?.overview,
                                        organizationName:
                                          topicWizardOverviewContent?.workplaceScenario?.background
                                            ?.organizationName,
                                        aboutOrganization:
                                          topicWizardOverviewContent?.workplaceScenario?.background
                                            ?.aboutOrganization,
                                        role: topicWizardOverviewContent?.workplaceScenario
                                          ?.learnerRoleReportingManager.learnerRole.role,
                                        summary:
                                          topicWizardOverviewContent?.workplaceScenario?.summary,
                                        organizationChallenge:
                                          topicWizardOverviewContent?.workplaceScenario?.challenge
                                            ?.currentIssue,
                                        roleDescription:
                                          topicWizardOverviewContent?.workplaceScenario
                                            ?.learnerRoleReportingManager.learnerRole
                                            .roleDescription,
                                        scopeOfWork:
                                          topicWizardOverviewContent?.workplaceScenario
                                            ?.learnerRoleReportingManager.learnerRole?.scopeOfWork,
                                        managerImgSrc:
                                          topicWizardOverviewContent?.workplaceScenario
                                            .learnerRoleReportingManager.reportingManager.avatarUrl,
                                        managerName:
                                          topicWizardOverviewContent?.workplaceScenario
                                            ?.learnerRoleReportingManager.reportingManager.name,
                                        managerRole:
                                          topicWizardOverviewContent?.workplaceScenario
                                            ?.learnerRoleReportingManager.reportingManager.role,
                                        managerMessage:
                                          topicWizardOverviewContent?.workplaceScenario
                                            ?.learnerRoleReportingManager.reportingManager.message,
                                        scenarioImage: topicWizardOverviewContent?.simulationImage,
                                      }}
                                    />
                                  ) : null}
                                  {/* <NoStyleDiv>{i18nCText(tabPane.editor || '')}</NoStyleDiv> */}
                                </div>
                              )}
                          </>
                        )}
                      </>
                    );
                  }
                })}
              </Col>
            </Row>
          </div>
        ) : null}
        <Div className="footer">
          <Footer />
        </Div>
      </Div>
    </Layout>
  );
}

export default ProjectPreview;

function RightSideContent(props: {
  isFetching: boolean;
  userProject: UserProject;
  workspaceId: string;
  projectId: string;
  currentStageId: string;
  currentPageId?: string;
  applyButton: () => JSX.Element;
}) {
  const navigate = useNavigate();
  const {
    isFetching,
    userProject,
    workspaceId,
    projectId,
    currentStageId,
    currentPageId,
    applyButton,
  } = props;
  if (isFetching) {
    return (
      <div style={{ marginBottom: '20px' }}>
        <Skeleton height={130} />
      </div>
    );
  }
  if (userProject.attributes.state === 'Finished') {
    return (
      <Card className="border-0 bg-gradient-success" tag="h5">
        <CardBody>
          <Row>
            <div className="col">
              <h1 className="text-white display-3">{translate('userProjects.state.Finished')}</h1>
            </div>
            <Col className="col-auto">
              <Button
                className="mx-1 btn-icon btn-3 invite-friends-btn"
                color="white"
                size="lg"
                onClick={() => {
                  navigate(`/workspace/${workspaceId}/challenge/${projectId}/report`);
                }}
              >
                <span className="btn-inner--text">{translate('projectPreview.viewReport')}</span>
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>
              </Button>
              <Button
                data-placement="top"
                id="viewChallengeTooltip_yljcc"
                className="mx-1 btn-icon btn-3 invite-friends-btn"
                color="white"
                size="lg"
                onClick={() => {
                  const tempUrl = `${projectId}/${currentStageId}/${String(currentPageId)}/created`;
                  navigate(`/workspace/${workspaceId}/challenge/${tempUrl}`);
                }}
              >
                <span className="btn-inner--icon">
                  <FaSignInAlt />
                </span>
              </Button>
              <UncontrolledTooltip delay={0} placement="top" target="viewChallengeTooltip_yljcc">
                {i18n.t('projectPreview.viewChallengeTooltip')}
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  } else {
    return <>{applyButton()}</>;
  }
}
