import { CardReviewAnswer } from '@app/@types/redux/answer';
import type { SimulationEmail, UserProjectSimulationChatItem } from '@app/@types/redux/pageElement';
import {
  Evaluation,
  FeedbackSubmitAnswer,
  PageElement,
  Project,
  ReviewStatus,
  UserAnswer,
  UserAnswerAnswer,
  UserAnswerFeedback,
  UserAnswerSubmitAnswer,
  UserEvaluation,
  UserProject,
} from '@app/@types/redux/project';
import {
  Badge,
  Country,
  Degree,
  Note,
  Notification,
  UnsplashResult,
  UpdateUserData,
  User,
  UserEducation,
  UserExperience,
  UserPreferences,
  UserSoftSkill,
} from '@app/@types/redux/users';
import { UserWorkspace, Workspace, WorkspaceResponseListItem } from '@app/@types/redux/workspace';
import env from '@config/env';
import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  RawAxiosRequestConfig,
} from 'axios';
import snakeCaseKeys from 'snakecase-keys';
import { getAccessToken, removeAccessToken, removeRefreshToken } from './helper';

interface MyRequestConfig {
  headers?: Record<string, string>;
  params?: Record<string, string | number>;
}

interface MyResponse<T> {
  data: T;
}

export const API_ROOT = () => {
  const url: string = env.API_URL;
  const version: string = env.API_VERSION;
  return `${url}/api/${version}`;
};

function getAuthToken(): string | null {
  return getAccessToken();
}

export const Api: AxiosInstance = Axios.create({
  baseURL: API_ROOT(),
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    user_type: 'student',
  },
});

Api.interceptors.request.use((config: AxiosRequestConfig & { headers: AxiosRequestHeaders }) => {
  const token = getAuthToken();
  const newConfig = { ...config };
  if (token) {
    newConfig.headers.access_token = token;
    newConfig.headers.user_type = 'student';
  }
  if (newConfig.headers['Content-Type'] === 'multipart/form-data') return newConfig;
  if (config.params) {
    if (Array.isArray(config.params)) {
      newConfig.params = config.params.map((param: Record<string, string | number>) =>
        snakeCaseKeys(param, { deep: true })
      );
    } else {
      newConfig.params = snakeCaseKeys(config.params, {
        deep: true,
      }) as unknown as Record<string, string | number>;
    }
  }
  if (config.data) {
    if (Array.isArray(config.data)) {
      newConfig.data = config.data.map((data: Record<string, string | number>) =>
        snakeCaseKeys(data, { deep: true })
      );
    } else {
      newConfig.data = snakeCaseKeys(config.data, {
        deep: true,
      }) as unknown as Record<string, string | number>;
    }
  }
  return newConfig;
});

const get = async <T>(url: string, config?: MyRequestConfig): Promise<MyResponse<T>> => {
  return Api.get(url, config);
};

const post = async <T>(
  url: string,
  data?: unknown,
  config?: MyRequestConfig
): Promise<MyResponse<T>> => {
  return Api.post(url, data, config);
};

const patch = async <T>(
  url: string,
  data?: unknown,
  config?: MyRequestConfig
): Promise<MyResponse<T>> => {
  return Api.patch(url, data, config);
};

const put = async <T>(
  url: string,
  data?: unknown,
  config?: MyRequestConfig
): Promise<MyResponse<T>> => {
  return Api.put(url, data, config);
};

// const destroy = async <T>(url: string, config?: MyRequestConfig): Promise<MyResponse<T>> => {
//   return Api.delete(url, config)
// }

export function purgeHeaders() {
  removeAccessToken();
  removeRefreshToken();
}

export interface OauthTokenResponse {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  refreshToken: string;
  createdAt: number;
  redirect?: string;
}

const expEndpoints = {
  me: async (): Promise<
    MyResponse<{
      data: User;
    }>
  > => {
    return get('/users/me');
  },
  getProjectsFilters: async () => {
    return get('/projects/filters');
  },
  getProjects: async (
    filters: string,
    page: number,
    perPage: number,
    workspaceId: string,
    isSignedIn: boolean
  ): Promise<
    MyResponse<{
      projects: Project[];
      meta: {
        total: number;
      };
    }>
  > => {
    return get('/projects', {
      params: {
        filters,
        page,
        per_page: perPage,
        workspace_id: workspaceId,
        is_signed_in: isSignedIn ? 'true' : 'false',
      },
    });
  },
  getUserCompanies: async () => {
    return get('/users/companies');
  },
  getUserHobbies: async () => {
    return get('/users/hobbies');
  },
  getUserCourses: async () => {
    return get('/users/courses');
  },
  getProject: async (id: string): Promise<MyResponse<{ data: Project }>> => {
    return get(`/projects/${id}`);
  },
  getUserProject: async (
    id: string
  ): Promise<
    MyResponse<{
      userAnswers: UserAnswer[];
      userProject: {
        data: UserProject;
      };
    } | null>
  > => {
    return get(`/projects/${id}/users`);
  },
  getEvaluation: async (id: string): Promise<MyResponse<{ data: Evaluation }>> => {
    return get(`/evaluations/${id}`);
  },

  getEvaluationByCode: async (code: string): Promise<MyResponse<{ data: Evaluation }>> => {
    return get(`/evaluations/code/${code}`);
  },
  getUserEvaluation: async (
    id: string
  ): Promise<
    MyResponse<{
      userAnswers: UserAnswer[];
      userEvaluation: {
        data: UserEvaluation;
      };
    }>
  > => {
    return get(`/evaluations/${id}/users`);
  },
  resetUserEvaluation: async (
    id: string
  ): Promise<
    MyResponse<{
      userEvaluation: { data: UserEvaluation };
    }>
  > => {
    return get(`/evaluations/${id}/users/reset`);
  },
  resetUserProject: async (
    id: string
  ): Promise<
    MyResponse<{
      data: UserProject;
    }>
  > => {
    return post(`/projects/${id}/reset`);
  },
  signUp: async (params: {
    email: string;
    password: string;
    password_confirmation: string;
  }): Promise<MyResponse<OauthTokenResponse>> => {
    return post('/users', { params });
  },
  getPreferences: async (): Promise<MyResponse<UserPreferences>> => {
    return get('/users/preferences');
  },
  getSignedUrl: async () => {
    return get('/users/s3_signed_url');
  },
  updatePreferences: async ({
    preferences,
  }: {
    preferences: UserPreferences;
  }): Promise<MyResponse<UserPreferences>> => {
    return post('/users/preferences', {
      preferences,
    });
  },
  updateAccountSettings: async (
    data: UpdateUserData
  ): Promise<
    MyResponse<{
      data: User;
    }>
  > => {
    return put('/settings/account', {
      ...data,
    });
  },
  updateExperienceSettings: async (
    experience: UserExperience,
    education: UserEducation
  ): Promise<MyResponse<User>> => {
    return put('/settings/experience', {
      ...experience,
      ...education,
    });
  },
  updatePassword: async (
    password: string,
    passwordConfirmation: string,
    currentPassword: string
  ): Promise<MyResponse<User>> => {
    return put('/settings/password', {
      password,
      passwordConfirmation,
      currentPassword,
    });
  },
  getCountries: async (): Promise<MyResponse<Country[]>> => {
    return get('/settings/countries');
  },
  updateProjectState: async (project_id: string, user_id: string) => {
    return put(`/projects/${project_id}/users/${user_id}`);
  },
  getMyBadges: async (): Promise<MyResponse<Badge[]>> => {
    return get('/users/learner/my_badges');
  },
  getMySkills: async (): Promise<MyResponse<{ data: UserSoftSkill[] }>> => {
    return get('/users/my_skills');
  },
  getMyProjects: async (
    page: number,
    perPage: number,
    sortDirection: string,
    sortBy: string,
    filters: string
  ): Promise<
    MyResponse<{
      data: UserProject[];
      meta: {
        total: number;
      };
    }>
  > => {
    return get('/users/learner/my_projects', {
      params: {
        page,
        per_page: perPage,
        sort_direction: sortDirection,
        sort_by: sortBy,
        filters,
      },
    });
  },
  applyToProject: async (
    id: string,
    token: string | null
  ): Promise<MyResponse<{ data: UserProject }>> => {
    return post(`/projects/${id}/apply`, {
      token,
    });
  },
  getNotifications: async (): Promise<MyResponse<{ data: Notification[] }>> => {
    return get('/users/notifications');
  },
  updateNotifications: async ({
    notificationIds,
  }: {
    notificationIds: string[];
  }): Promise<MyResponse<{ data: Notification[] }>> => {
    return post('/users/notifications', { notificationIds });
  },
  sendAnswers: async (
    id: string,
    stageId: string,
    answers: UserAnswerSubmitAnswer[]
  ): Promise<
    MyResponse<{
      growths: [];
      notifications: {
        data: Notification[];
      };
      project: {
        data: Project;
      };
      reviewStatus: ReviewStatus;
      userProject: {
        data: UserProject;
      };
    }>
  > => {
    return post(`/projects/${id}/stages/${stageId}`, {
      user_answers: answers,
    });
  },
  sendInvites: async (id: string) => {
    return post(`/projects/${id}/invite`);
  },
  sendReview: async (
    id: string,
    user_answer_id: string,
    user_answer: CardReviewAnswer,
    reviewing_from: string
  ): Promise<
    MyResponse<{
      reviewStatus: ReviewStatus;
    }>
  > => {
    return post(`/projects/${id}/user_answer_review/${user_answer_id}`, {
      user_answer,
      reviewing_from,
    });
  },
  sendFeedback: async (
    id: string,
    page_element_id: string,
    answer: FeedbackSubmitAnswer
  ): Promise<
    MyResponse<{
      data: Notification[];
    }>
  > => {
    return post(`/projects/${id}/feedback/${page_element_id}`, {
      user_answer: answer,
    });
  },
  sendEvaluationAnswers: async (
    id: string,
    answers: UserAnswerSubmitAnswer[]
  ): Promise<
    MyResponse<{
      data: UserEvaluation;
    }>
  > => {
    return post(`/evaluations/${id}`, {
      user_answers: answers,
    });
  },
  getReviews: async (projectId: string, pageElementId: string) => {
    return get(`/projects/${projectId}/reviews/${pageElementId}`);
  },
  getFeedback: async (
    projectId: string,
    pageElementId: string
  ): Promise<
    MyResponse<{
      data: UserAnswerFeedback[];
    }>
  > => {
    return get(`/projects/${projectId}/feedback/${pageElementId}`);
  },
  getDegree: async ({ id }: { id: string }): Promise<MyResponse<Degree>> => {
    return get(`/schools/degree/${id}`);
  },
  login: async (params: {
    email: string;
    password: string;
  }): Promise<MyResponse<OauthTokenResponse>> => {
    const { email, password } = params;
    return post('/oauth/token', {
      email,
      password,
      grant_type: 'password',
      user_type: 'student',
      domain: window.location.href,
    });
  },
  forgotPassword: async (params: { email: string }) => {
    return post('/users/forgot_password', { ...params });
  },
  sendMagicLink: async (params: { email: string; redirect: string }) => {
    return post('/users/send_magic_link', { ...params });
  },
  resendConfirmationMail: async () => {
    return post('/users/resend_confirmation');
  },
  confirmAccount: async (params: {
    token: string;
  }): Promise<MyResponse<{ data: OauthTokenResponse }>> => {
    return post('/users/confirm_user', { ...params });
  },
  verifyToken: async (params: {
    token: string;
  }): Promise<MyResponse<{ data: OauthTokenResponse }>> => {
    return post('/users/verify_token', { ...params });
  },
  resetPassword: async (params: {
    token: string;
    password: string;
    password_confirmation: string;
  }) => {
    return patch('/users/reset_password', { ...params });
  },
  resetPasswordTokenValid: async ({ token }: { token: string }) => {
    return post('/users/reset_password_token_valid', {
      token,
    });
  },
  refresh: async ({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<MyResponse<OauthTokenResponse>> => {
    return post('/oauth/token', {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    });
  },
  getEvaluations: async (): Promise<
    MyResponse<{
      evaluations: {
        data: Evaluation[];
      };
      myEvaluations: {
        data: UserEvaluation[];
      };
    }>
  > => {
    return get('/evaluations');
  },
  getResources: async () => {
    return get('/repository_assets/resources');
  },
  getNotes: async (): Promise<MyResponse<Note[]>> => {
    return get('/notes/user_notes');
  },
  updateNote: async (data: Note['attributes']): Promise<MyResponse<Note>> => {
    const { id }: { id: string } = data;
    return put(`/notes/${id}`, {
      ...data,
    });
  },
  getNote: async (id: string): Promise<MyResponse<{ data: Note }>> => {
    return get(`/notes/${id}`);
  },
  gibberishDetector: async () => {
    return post('/callbacks/gibberish_detector/check_text');
  },
  getFinalSubmissions: async (
    project_id: string,
    page: string,
    per_page: string
  ): Promise<
    MyResponse<{
      finalSubmissions: {
        data: UserAnswer[];
      };
      total: number;
    }>
  > => {
    return get(`/projects/${project_id}/final_submissions/${page}/${per_page}`);
  },
  getIdeas: async (
    project_id: string,
    page: string,
    per_page: string
  ): Promise<
    MyResponse<{
      ideas: {
        data: UserAnswer[];
      };
      total: number;
    }>
  > => {
    return get(`/projects/${project_id}/ideas/${page}/${per_page}`);
  },
  getPersonas: async (
    project_id: string,
    page: string,
    per_page: string
  ): Promise<
    MyResponse<{
      personas: {
        data: UserAnswer[];
      };
      total: number;
    }>
  > => {
    return get(`/projects/${project_id}/personas/${page}/${per_page}`);
  },
  getUserAnswer: async (
    project_id: string,
    user_answer_id: string
  ): Promise<
    MyResponse<{
      userAnswer: {
        data: UserAnswer;
      };
    }>
  > => {
    return get(`/projects/${project_id}/user_answer/${user_answer_id}`);
  },
  getSimulationEmails: async (
    project_id: string,
    page_element_id = ''
  ): Promise<
    MyResponse<{
      data: SimulationEmail[];
    }>
  > => {
    return get(`/projects/${project_id}/simulation_emails?page_element_id=${page_element_id}`);
  },
  getSimulationChats: async (
    project_id: string,
    page_element_id = ''
  ): Promise<
    MyResponse<{
      data: UserProjectSimulationChatItem[];
    }>
  > => {
    return get(`/projects/${project_id}/simulation_chat_items?page_element_id=${page_element_id}`);
  },
  putSimulationEmailOpened: async (
    project_id: string,
    simulation_email_id: string
  ): Promise<
    MyResponse<{
      data: SimulationEmail;
    }>
  > => {
    return put(`/projects/${project_id}/simulation_email/${simulation_email_id}/mark_opened`);
  },
  getSimulationEmailById: async (
    project_id: string,
    simulation_email_id: string
  ): Promise<
    MyResponse<{
      data: SimulationEmail;
    }>
  > => {
    return get(`/projects/${project_id}/simulation_email/${simulation_email_id}`);
  },
  getUserAnswerFromPageElement: async (project_id: string, page_element_id: string) => {
    return get(`/projects/${project_id}/user_answer_id/${page_element_id}`);
  },
  getReviewable: async (
    project_id: string,
    user_answer_id: string,
    user_id: string
  ): Promise<
    MyResponse<{
      reviewableItems: {
        data: UserAnswer[];
      };
      review: UserAnswerAnswer;
      reviewPageElements: {
        data: PageElement[];
      };
    }>
  > => {
    return get(`/projects/${project_id}/user_answer_reviewable/${user_answer_id}`, {
      params: {
        user_id,
      },
    });
  },
  getReviewStatus: async (project_id: string): Promise<MyResponse<ReviewStatus>> => {
    return get(`/projects/${project_id}/reviews`);
  },
  getToReviewAnswer: async (
    project_id: string,
    page_element_id: string
  ): Promise<
    MyResponse<{
      data: string;
    }>
  > => {
    return get(`/projects/${project_id}/review/${page_element_id}`);
  },
  getReferralGroups: async () => {
    return get('/users/referral_groups');
  },
  getMyWorkspaces: async (
    page: number,
    perPage: number,
    sortDirection: string,
    sortBy: string
  ): Promise<
    MyResponse<{
      workspaces: WorkspaceResponseListItem[];
      totalWorkspaces: number;
    }>
  > => {
    return get('/learner/workspaces', {
      params: {
        page,
        per_page: perPage,
        sort_direction: sortDirection,
        sort_by: sortBy,
      },
    });
  },
  getCurrentWorkspace: async (
    id: string
  ): Promise<
    MyResponse<{
      data: Workspace;
    }>
  > => {
    return get(`/workspaces/${id}`);
  },
  getPaymentUrl: async (
    id: string
  ): Promise<MyResponse<{ data: UserWorkspace } | { url: string }>> => {
    return get(`/workspaces/${id}/payment/url`);
  },
  postPaymentVerify: async (id: string): Promise<MyResponse<{ data: UserWorkspace }>> => {
    return post(`/workspaces/${id}/payment/verify`, {
      workspace_id: id,
    });
  },
  getUserWorkspace: async (
    id: string
  ): Promise<
    MyResponse<{
      data: UserWorkspace;
    }>
  > => {
    return get(`/workspaces/${id}/user_workspace/learner`);
  },
  postWorkspaceVoucherVerify: async (
    id: string,
    voucherCode: string
  ): Promise<MyResponse<{ data: UserWorkspace }>> => {
    return post(`/workspaces/${id}/voucher/verify`, {
      workspace_id: id,
      voucherCode,
    });
  },
  fetchMetaData: async (
    url: string
  ): Promise<
    MyResponse<{
      data: {
        title: string;
        description: string;
        image: string;
        url: string;
        favicon: string;
        siteName: string;
      };
    }>
  > => {
    return get(`/helpers/fetch_metadata?url=${url}`);
  },
  lifeCraft: async (page_element_id: string) => {
    return get(`/projects/life_craft/${page_element_id}`);
  },
  lifeCraftChatResponse: async (page_element_id: string) => {
    return get(`/projects/life_craft/${page_element_id}/chat_response`);
  },
  getFeedbackStatus: async (
    project_id: string
  ): Promise<
    MyResponse<
      {
        cardFeedbackId: string;
        leftToReview: number;
        pageId: string;
        stageId: string;
      }[]
    >
  > => {
    return get(`/projects/${project_id}/feedbacks`);
  },
  upload: async (
    file: FormData,
    options: RawAxiosRequestConfig
  ): Promise<MyResponse<{ url: string }>> => {
    return post('/upload', file, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  unsplashSearch: async (
    query: string,
    perPage: number,
    page: number
  ): Promise<
    MyResponse<{
      results: UnsplashResult[];
      meta: {
        total: number;
        pages: number;
      };
    }>
  > => {
    return get('/unsplash/search', {
      params: {
        query,
        per_page: perPage,
        page,
      },
    });
  },
};

export default expEndpoints;
