import type { ChatActivityAnswer, InputStyle4Answer } from '@app/@types/redux/answer';
import type {
  CanvasOptions,
  ChatActivityOptions,
  SimulationChatItem,
  TextareaOptions,
} from '@app/@types/redux/pageElement';
import type { DependeeValue } from '@app/@types/redux/project';
import type { PageElementProps } from '@components/Projects/TakeProject';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import logger from '@libs/log';
import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '@state/redux/store';
import { getDependeeValues } from '@state/selectors/projects';
import {
  getCompletions,
  getGenerativeDataFromPrompt,
  type Message,
  type OpenAIRole,
} from '@state/utils/openai';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import ChatBox from './ChatBox';
import ChatMenu from './ChatMenu';
import './index.scss';
import { Chats } from './tempConstants';

export interface ChatItem {
  id: string;
  message: SimulationChatItem['attributes']['message'];
  sender: OpenAIRole;
  timestamp: string;
  simulationChatItem?: SimulationChatItem;
}
interface ChatActivityProps extends PageElementProps {
  tempMessages?: ChatItem[];
}
const TempChat: React.FC<ChatActivityProps> = (props: ChatActivityProps) => {
  const { answer, pageElement, state, tempMessages } = props;
  const topicWizardData = useAppSelector(
    (state) =>
      state.projects.currentProject.project.attributes.options.creationOptions.topicWizardData
  );
  const sampleChatActivityOptions: ChatActivityOptions = {
    activityData: {
      submissionQuestions: [
        {
          id: 'q1',
          question: 'What is your name?',
          isDefault: true,
          visible: true,
          criteria: ['required'],
          modelAnswer: 'John Doe',
        },
        {
          id: 'q2',
          question: 'Describe your project.',
          isDefault: false,
          visible: true,
          criteria: ['required', 'length:100'],
        },
      ],
    },
    chatActivityData: {
      id: 'chat1',
      value: 'Chat Activity 1',
      description: 'This is a sample chat activity.',
      purpose: 'To facilitate communication among team members.',
      rules: [
        {
          id: 'r1',
          checked: true,
          description: 'Be respectful to others.',
          defaultRule: true,
        },
        {
          id: 'r2',
          checked: false,
          description: 'No spamming.',
          defaultRule: false,
        },
      ],
      users: [
        {
          id: 'u1',
          name:
            topicWizardData.workplaceScenario?.learnerRoleReportingManager.reportingManager.name ??
            'Alice',
          profileImage:
            topicWizardData.workplaceScenario?.learnerRoleReportingManager.reportingManager
              .avatarUrl ?? 'https://randomuser.me/api/portraits/women/1.jpg',
          designation: 'Developer',
          personality: 'Friendly',
          organization: 'Tech Corp',
        },
        {
          id: 'u2',
          name: 'Bob',
          profileImage: 'https://randomuser.me/api/portraits/men/1.jpg',
          designation: 'Designer',
          personality: 'Creative',
          organization: 'Design Studio',
        },
      ],
      dependentActivityId: 'activity123',
      duration: '30 minutes',
    },
    reviewData: {
      selectedReviewTypes: ['peer', 'self'],
      reviewType: 'star',
      starRatingQuestions: [
        {
          id: 'srq1',
          question: 'How would you rate the overall experience?',
          isDefault: true,
          visible: true,
          criteria: ['required'],
        },
        {
          id: 'srq2',
          question: 'How likely are you to recommend this to a friend?',
          isDefault: false,
          visible: true,
          criteria: ['required'],
        },
      ],
      peerReviewCompetencyQuestions: [
        {
          id: 'prcq1',
          question: 'How well did the team collaborate?',
          isDefault: true,
          visible: true,
          criteria: ['required'],
        },
        {
          id: 'prcq2',
          question: 'How effectively did the team communicate?',
          isDefault: false,
          visible: true,
          criteria: ['required'],
        },
      ],
    },
  };
  const currentAnswer = props.currentAnswer as ChatActivityAnswer | undefined;

  const { dependees } = pageElement.attributes;

  const dependentValues = getDependeeValues(dependees, [], state) as DependeeValue[];

  const [dependentAnswer, setDependentAnswer] = useState('');

  const [modelAnswer, setModelAnswer] = useState('');

  useEffect(() => {
    if (!currentAnswer?.dependentAnswer && dependentValues.length > 0) {
      let dependentAnswer = '';
      let modelAnswer = '';
      if (
        (dependentValues[0].dependeePageElement.options as CanvasOptions).canvasType ===
        'swot_canvas_creator'
      ) {
        const strengths = (
          dependentValues.find(
            (dv) =>
              dv.pageElement &&
              (dv.pageElement.options as TextareaOptions).label.toLowerCase() === 'strengths'
          )?.answer as InputStyle4Answer
        ).values
          .map((v) => v.value)
          .join(', ');

        const weaknesses = (
          dependentValues.find(
            (dv) =>
              dv.pageElement &&
              (dv.pageElement.options as TextareaOptions).label.toLowerCase() === 'weaknesses'
          )?.answer as InputStyle4Answer
        ).values
          .map((v) => v.value)
          .join(', ');

        const opportunities = (
          dependentValues.find(
            (dv) =>
              dv.pageElement &&
              (dv.pageElement.options as TextareaOptions).label.toLowerCase() === 'opportunities'
          )?.answer as InputStyle4Answer
        ).values
          .map((v) => v.value)
          .join(', ');

        const threats = (
          dependentValues.find(
            (dv) =>
              dv.pageElement &&
              (dv.pageElement.options as TextareaOptions).label.toLowerCase() === 'threats'
          )?.answer as InputStyle4Answer
        ).values
          .map((v) => v.value)
          .join(', ');

        dependentAnswer = `Strengths: ${strengths}\nWeaknesses: ${weaknesses}\nOpportunities: ${opportunities}\nThreats: ${threats}`;
      }
      if (
        (dependentValues[0].dependeePageElement.options as CanvasOptions).canvasType ===
        'custom_canvas_creator' ||
        dependentValues[0].dependeePageElement.elementType === PAGE_ELEMENT.EXECUTIVE_SUMMARY
      ) {
        dependentAnswer = ``;
        dependentValues.forEach((dv) => {
          const label = dv.pageElement ? (dv.pageElement.options as TextareaOptions).label : '';
          const values = dv.answer as string;
          dependentAnswer += `QUESTION: ${label}\nANSWER:${values}\n`;
        });
        const questions =
          (pageElement.attributes.options as ChatActivityOptions).activityData
            .submissionQuestions ??
          (pageElement.attributes.options as ChatActivityOptions).activityData.questions?.map(
            (q) => ({ ...q, visible: true })
          ) ??
          [];
        modelAnswer = questions
          .filter((q) => q.visible)
          .map(
            (question, _i) =>
              `\n${String(_i + 1)}. ${question.question}\n${question.modelAnswer ?? ''}`
          )
          .join('\n');
      }
      setDependentAnswer(dependentAnswer);
      setModelAnswer(modelAnswer);
      if (dependentAnswer !== currentAnswer?.dependentAnswer) {
        answer({
          ...currentAnswer,
          dependentAnswer,
        } as ChatActivityAnswer);
      }
    }
  }, [dependentValues]);

  const [searchboxValue, setSearchboxValue] = useState('');

  // const user = (pageElement.attributes.options as ChatActivityOptions).chatActivityData.users[0];
  const user = sampleChatActivityOptions.chatActivityData.users[0];

  const [selectedChat, setSelectedChat] = useState(user.id);

  const [messages, setMessages] = useState<ChatItem[]>(
    currentAnswer?.messages ?? tempMessages ?? []
  );

  const chats = [
    {
      id: user.id,
      name: user.name,
      lastMessage: messages[messages.length - 1]?.message ?? '',
      lastMessageTime: moment(messages[messages.length - 1]?.timestamp ?? '').format('hh:mm A'),
      profilePicture: user.profileImage,
      messageStatus: 'default',
      online: true,
    },
    ...Chats,
  ];

  // const chatDuration = Number(
  //   (pageElement.attributes.options as ChatActivityOptions).chatActivityData.duration
  // );
  const chatDuration = Number(sampleChatActivityOptions.chatActivityData.duration);

  const userRedux = useAppSelector((state) => state.users.user);

  const systemPrompt = `You are ${user.name}, a ${user.designation} at ${user.organization
    }. You are conducting a review of a recent work assignment completed by intern/student with name ${userRedux.attributes.name
    } as part of a business simulation exercise. Your role is to assess the student's ability to justify their approach, demonstrate content mastery, and provide rational justifications for their submission.

In this role, you MUST actively identify elements in the student's submission that differ from or align with the model answer and ask targeted questions about those specific points to ensure the conversation is grounded in the student's work and the expected answers. Pinpoint specific sections of the submission where the student's approach is unique, incomplete, or particularly strong, and use these sections as focal points for questioning.
During this ${chatDuration.toString()} minutes chat: Begin with a Friendly Introduction: Start by acknowledging the student's effort and setting the purpose of the conversation: to assess their understanding of the task, clarify their choices, and evaluate their ability to support their work with sound arguments.

Pose Questions to Test Key Skills:
- Clarity of Thought: Ask the student to explain their approach, prompting them to walk you through their thought process.
- Critical Thinking and Justification: Pose questions that compare elements of their submission to a model answer. Ask them to justify any deviations or explain the rationale behind their choices.
- Real-World Application: Ask the student how their approach would apply to a real-world situation and if there are any specific examples that support their solution.
Use Guardrails for Relevance and Professionalism:
- Redirect Off-Topic Responses: If the student strays off-topic or uses unprofessional language, gently redirect them with a phrase like, “Let's focus on the task to make the best use of our time.”
- Prohibit Profanity and Irrelevance: If the student uses profanity, respond by reminding them of the professional setting with a phrase like, “Let's maintain a professional tone to keep our focus.”

Contextual Tone and Voice: Maintain a supportive yet critical tone. The goal is to test the student's understanding without intimidating them.
Adaptive Assessment:
- If certain skills cannot be assessed due to time or relevance, focus on skills that are naturally demonstrated through the student's responses.
- Prioritize assessing the skills most relevant to the student's answers (e.g., if they exhibit strong problem-solving, delve deeper into that area).
Sample Questions:
- “Could you walk me through your decision-making process for [specific aspect]?”
- “How does your approach compare to [model answer's approach], and what was your reasoning?”
- “What real-world example can you give to support your proposal?”
- “How would you address any potential weaknesses in your approach?”
- “If you had to defend your approach to senior leadership, how would you justify it?”
- “What industry examples can you reference to back up your proposal?”
- “What metrics or evidence would you use to measure the success of your approach?”
Remember to keep the conversation professional and focused on the task. Continue this line of questioning and end the chat naturally within the allocated time.

STUDENT'S SUBMISSION:
${dependentAnswer}

MODEL ANSWER:
${modelAnswer}


Now start the chat with the student. Go one question at a time.`;

  useEffect(() => {
    if (messages.length === 0) {
      const promptArray: Message[] = [
        {
          role: 'system',
          content: systemPrompt,
        },
      ];
      getCompletions(promptArray)
        .then((response) => {
          const message = response.choices[0].message.content;
          setMessages([
            ...messages,
            {
              id: nanoid(),
              message,
              sender: 'assistant',
              timestamp: new Date().toISOString(),
            },
          ]);
        })
        .catch(() => {
          logger.error('Error getting generative data from prompt');
        });
    }
  }, []);

  useEffect(() => {
    if (messages.length && messages[messages.length - 1].sender === 'user') {
      const gptMessages = messages.map((message) => {
        return {
          role: message.sender,
          content: message.message,
        };
      });
      getCompletions([
        {
          role: 'system',
          content: systemPrompt,
        },
        ...gptMessages,
      ])
        .then((response) => {
          const message = response.choices[0].message.content;
          setMessages([
            ...messages,
            {
              id: nanoid(),
              message,
              sender: 'assistant',
              timestamp: new Date().toISOString(),
            },
          ]);
        })
        .catch(() => {
          logger.error('Error getting completions');
        });
    }
    answer({
      ...currentAnswer,
      dependentAnswer,
      messages,
    } as ChatActivityAnswer);
  }, [messages]);

  // if firstUserMessage was more than 5 minutes ago, end the chat

  useEffect(() => {
    const firstUserMessage = messages.find((message) => message.sender === 'user');
    if (firstUserMessage && !currentAnswer?.chatEnded) {
      const interval = setInterval(() => {
        if (
          new Date().getTime() - new Date(firstUserMessage.timestamp).getTime() >
          chatDuration * 60 * 1000
        ) {
          const outputFormat = {
            message: 'string',
          };
          const promptArray = [
            {
              prompt: `Go through the conversation and end the chat.

End the Chat Naturally as the assistant: As the allocated time approaches, conclude the chat with a natural, realistic ending. For instance, say, “I think we've covered what we needed—thanks for sharing your thoughts,” or “I have a meeting coming up, so let's wrap up here.” If you're satisfied with the student's responses, express appreciation with a closing remark like, “Thank you for this discussion; it was insightful.”

CHAT HISTORY:
${messages.map((message) => `\n${message.sender.toUpperCase()}: ${message.message}`).join('\n')}
              `,
              outputFormat,
            },
          ];
          getGenerativeDataFromPrompt<typeof outputFormat>(promptArray)
            .then((response) => {
              const message = response.message;
              setMessages([
                ...messages,
                {
                  id: nanoid(),
                  message,
                  sender: 'assistant',
                  timestamp: new Date().toISOString(),
                },
              ]);
            })
            .catch(() => {
              logger.error('Error getting generative data from prompt');
            });
          clearInterval(interval);
          answer({
            ...currentAnswer,
            chatEnded: true,
          } as ChatActivityAnswer);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="left-side">
        <div className="chat-search-box-container">
          <div>
            <FaSearch className="chat-search-box-fixed-label" />
          </div>
          <div className="chat-input-wrapper">
            <input
              type="text"
              value={searchboxValue}
              onChange={(e) => {
                setSearchboxValue(e.target.value);
              }}
              placeholder="Search..."
            />
          </div>
        </div>
        {chats.map((chat, index) => (
          <ChatMenu
            key={index}
            chat={chat}
            selected={selectedChat === chat.id}
            handleOnClick={() => {
              setSelectedChat(chat.id);
            }}
          />
        ))}
      </div>
      <div className="right-side">
        <ChatBox messages={messages} avatar={user} setMessages={setMessages} disabled={true} />
      </div>
    </div>
  );
};
export default TempChat;
