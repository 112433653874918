/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
// why am i doing this ?, honestly i have no idea eslint is yelling at me
// for no apparent reason.
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@state/redux/store';
import './Message.scss';
import './Scenario.scss';

import type { Workspace } from '@app/@types/redux/workspace';
import { Collapse } from '@components/UI/Html';
import NoStyleDiv from '@components/UI/Html/NoStyleDiv';
import { i18nCText } from '@libs/i18n/I18n';
import { parseMDX } from '@state/utils/helper';
import { CSSTransition } from 'react-transition-group';
import { Card, CardBody, CardHeader } from 'reactstrap';

export interface ScenarioDataProps {
  learningOutcomes?: { keyLearningOutcome: string }[];
  overview?: string;
  simulationType?: string;
  organizationName?: string;
  aboutOrganization?: string;
  role?: string;
  summary?: string;
  organizationChallenge?: string;
  roleDescription?: string;
  scopeOfWork?: { task: string; description: string }[];
  managerImgSrc?: string;
  managerName?: string;
  managerRole?: string;
  managerMessage?: string;
  scenarioImage?: string;
}

// TODO
// escape the html content to prevent xss attacks
export const ScenarioOverview: React.FC<{
  scenarioData: ScenarioDataProps;
  workspaceData?: Workspace;
}> = (props) => {
  const { scenarioData, workspaceData } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="ov-container">
      {(scenarioData.summary || (scenarioData.learningOutcomes?.length ?? 0) > 0) && (
        <Card className="rounded">
          <CardBody>
            <>
              <div className="sc-subheader">Overview</div>
              <div className="sc-header">Learning Outcomes & Summary</div>
              <p className="sc-text">{scenarioData.summary}</p>
              <ol className="sc-text list-disc">
                {scenarioData.learningOutcomes?.map((outcome, index) => (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: parseMDX(outcome.keyLearningOutcome),
                    }}
                  />
                ))}
              </ol>
            </>
          </CardBody>
        </Card>
      )}
      {(scenarioData.aboutOrganization ||
        scenarioData.organizationChallenge ||
        (scenarioData.scopeOfWork?.length ?? 0) > 0 ||
        scenarioData.managerName) && (
        <Card className="rounded">
          <CardBody>
            {scenarioData.aboutOrganization && (
              <>
                <div className="sc-subheader">simulation details</div>
                <div className="sc-header">About the Organization</div>
                <p
                  className="sc-text"
                  dangerouslySetInnerHTML={{
                    __html: parseMDX(scenarioData.aboutOrganization),
                  }}
                />
              </>
            )}
            {scenarioData.organizationChallenge && (
              <>
                <div className="sc-subheader sc-top">problem</div>
                <div className="sc-header">Business Challenge</div>
                <p
                  className="sc-text"
                  dangerouslySetInnerHTML={{
                    __html: parseMDX(scenarioData.organizationChallenge),
                  }}
                />
              </>
            )}
            {scenarioData.scopeOfWork && (
              <div className="sc-learner-container">
                <div className="sc-subheader">Learner's Role</div>
                <div className="sc-learner-header">{scenarioData.role}</div>
                <div className="sc-learner-text">{scenarioData.roleDescription}</div>
                <div className="sc-subheader">SCOPE OF WORK</div>
                <ol className="sc-learner-text list-disc">
                  {scenarioData.scopeOfWork.map((scope, index) => (
                    <li key={index}>
                      <strong style={{ fontWeight: '500' }}>
                        {scope.task ? `${scope.task}:` : ''}
                      </strong>
                      &nbsp;
                      {scope.description}
                    </li>
                  ))}
                </ol>
              </div>
            )}
            {scenarioData.managerName && (
              <div className="sc-organization-container">
                <div className="sc-subheader">REPORTING MANAGER </div>
                <div className="sc-manager-container">
                  <img className="sc-manager-img" src={scenarioData.managerImgSrc} />
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <div className="sc-manager-name">{scenarioData.managerName},</div>
                      <div className="sc-subheader">{scenarioData.managerRole}</div>
                    </div>
                    <div className="sc-text">{scenarioData.managerMessage}</div>
                  </div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      )}
      {workspaceData && (
        <div className="accordion">
          <Card className="rounded" style={{ overflow: 'hidden' }}>
            <CardHeader
              role="tab"
              onClick={() => {
                setOpen(!open);
              }}
              aria-expanded={open}
            >
              <div className="mr-5">
                <div className="sc-subheader mt-2">assessment</div>
                <div className="sc-header">AI-assisted Grading</div>
                <p className="sc-text">
                  This simulation uses AI-assisted grading to provide real-time, personalized
                  feedback. The AI is trained by your professor using the same rubric they created
                  for this course, ensuring that the grading reflects their standards.
                </p>
              </div>
            </CardHeader>
            <Collapse isOpen={open} role="tabpanel">
              <CardBody>
                <CSSTransition in={open} unmountOnExit mountOnEnter timeout={100}>
                  <>
                    {workspaceData?.attributes.options?.simulationSettings?.map(
                      (setting, index) => (
                        <div
                          key={setting.id}
                          className={`sc-accordion-item ${
                            index !== 0 ? 'sc-accordion-border-top' : ''
                          }`}
                        >
                          <div className="">
                            <div className="sc-accordion-header">{setting.title}</div>
                            <p className="sc-text">{setting.description}</p>
                          </div>
                          <span className="sc-accordion-header">{setting.value}</span>
                        </div>
                      )
                    )}
                  </>
                </CSSTransition>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      )}
    </div>
  );
};

const Message: React.FC<{ htmlContent: string }> = ({ htmlContent }) => {
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const currentWorkspace = useAppSelector((state) => state.workspaces.currentWorkspace);

  const [scenarioData, setScenarioData] = useState<ScenarioDataProps>({
    learningOutcomes: [],
    simulationType: '',
    organizationName: '',
    aboutOrganization: '',
    role: '',
    summary: '',
    organizationChallenge: '',
    roleDescription: '',
    scopeOfWork: [] as { task: string; description: string }[],
    managerImgSrc: '',
    managerName: '',
    managerRole: '',
    managerMessage: '',
    scenarioImage: currentProject.project.attributes.imageData,
  });

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const getTextContent = (label: string) => {
      const strongElements = Array.from(doc.querySelectorAll('strong'));
      const strongElement = strongElements.find((el) =>
        el.textContent?.toLowerCase().includes(label.toLowerCase())
      );
      if (strongElement) {
        const parent = strongElement.closest('div');
        return parent
          ? parent.textContent?.replace(strongElement.textContent ?? '', '').trim()
          : '';
      }
      return '';
    };

    const getOrderedList = (title: string) => {
      const strongElements = Array.from(doc.querySelectorAll('strong'));
      const titleElement = strongElements.find((el) =>
        el.textContent?.toLowerCase().includes(title.toLowerCase())
      );

      if (titleElement) {
        let currentElement: Element | null = titleElement.closest('div');
        while (currentElement) {
          const nextList = currentElement.nextElementSibling;
          if (nextList) {
            return Array.from(nextList.querySelectorAll('li')).map(
              (li) => li.textContent?.trim() ?? ''
            );
          }
          currentElement = currentElement.nextElementSibling;
        }
      }

      return [];
    };

    const getScopeOfWorkList = (title: string) => {
      const strongElements = Array.from(doc.querySelectorAll('strong'));
      const titleElement = strongElements.find((el) =>
        el.textContent?.toLowerCase().includes(title.toLowerCase())
      );

      if (titleElement) {
        let currentElement: Element | null = titleElement.closest('div');
        while (currentElement) {
          const nextList = currentElement.nextElementSibling;
          if (nextList) {
            return Array.from(nextList.querySelectorAll('li')).map((li) => {
              return {
                task: li.textContent?.split(':')[0]?.trim() ?? '',
                description: li.textContent?.split(':')[1]?.trim() ?? '',
              };
            });
          }
          currentElement = currentElement.nextElementSibling;
        }
      }

      return [];
    };

    setScenarioData({
      ...scenarioData,
      overview: getTextContent('Overview:') ?? '',
      simulationType: getTextContent('Simulation Type:') ?? '',
      organizationName: getTextContent('Organization Name:') ?? '',
      aboutOrganization: getTextContent('About the Organization:') ?? '',
      role: getTextContent('Role:') ?? '',
      summary: getTextContent('Scenario Summary:') ?? '',
      organizationChallenge: getTextContent('Organizational Challenge:') ?? '',
      roleDescription: getTextContent('Role Description:') ?? '',
      learningOutcomes: getOrderedList('Learning Outcomes:').map((keyLearningOutcome) => ({
        keyLearningOutcome,
      })),
      scopeOfWork: getScopeOfWorkList('Scope of Work:'),
      managerImgSrc: getTextContent('Reporting Manager Image:') ?? '',
      managerName: getTextContent('Reporting Manager Name:') ?? '',
      managerRole: getTextContent('Reporting Manager Role:') ?? '',
      managerMessage: getTextContent('Reporting Manager Message:') ?? '',
    });
  }, [htmlContent]);

  function hasEmptyKeys(data: ScenarioDataProps, keysToCheck: (keyof ScenarioDataProps)[]) {
    return keysToCheck.every((key) => {
      const value = data[key];
      return (
        value === undefined ||
        value === null ||
        (typeof value === 'string' && value.trim() === '') ||
        (Array.isArray(value) && value.length === 0)
      );
    });
  }

  return hasEmptyKeys(scenarioData, [
    'learningOutcomes',
    'overview',
    'simulationType',
    'organizationName',
    'aboutOrganization',
    'role',
    'summary',
    'organizationChallenge',
    'roleDescription',
    'scopeOfWork',
    'managerImgSrc',
    'managerName',
    'managerRole',
    'managerMessage',
  ]) ? (
    <Card className="rounded">
      <CardBody>
        <NoStyleDiv>{i18nCText(htmlContent ?? '')}</NoStyleDiv>
      </CardBody>
    </Card>
  ) : (
    <ScenarioOverview scenarioData={scenarioData} workspaceData={currentWorkspace} />
  );
};

export default Message;
