import { CanvasAnswer } from '@app/@types/redux/answer';
import { TextareaOptions, type CanvasOptions } from '@app/@types/redux/pageElement';
import { i18nCText } from '@libs/i18n/I18n';
import { nanoid } from '@reduxjs/toolkit';
import _ from 'lodash';
import './CustomCanvas.scss';

interface CustomCanvasProps {
  elements: CanvasAnswer;
  questions: string[];
  options: CanvasOptions;
}
function CustomCanvas({ elements, questions, options }: CustomCanvasProps) {
  const sortedCollection = _.sortBy(elements, (item) => {
    const options = item.pageElement.options as TextareaOptions;
    return questions.indexOf(options.label);
  });
  const pElements = sortedCollection.map((element) => {
    const textareaOptions = element.pageElement.options as TextareaOptions;
    return (
      <div className="m-0 summaryBlock" key={nanoid()}>
        <h2 className="summaryBlock__question mt-4">{i18nCText(textareaOptions.label)}</h2>
        <p
          className="summaryBlock__answer mb-4 text-justify"
          style={{
            whiteSpace: 'pre-wrap',
          }}
          dangerouslySetInnerHTML={{ __html: element.answer as string }}
        />
      </div>
    );
  });

  return (
    <div className="exs-container">
      <div className="headingBlock">
        <div className="w-100">
          <h1 className="title">{options.title ? options.title : 'Summary'}</h1>
          <div className="d-flex justify-content-between w-100">
            <p className="subtitle">{options.subtitle ?? ''}</p>
            <time className="year">{new Date().getFullYear()}</time>
          </div>
        </div>
      </div>
      {pElements}
    </div>
  );
}

export default CustomCanvas;
