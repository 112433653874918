/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ExecutiveSummaryAnswer } from '@app/@types/redux/answer';
import type { ExecutiveSummaryOptions } from '@app/@types/redux/pageElement';
import env from '@config/env';
import { Editor } from '@tinymce/tinymce-react'; // Import Editor directly
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';

const EditorCanvas = ({
  question,
  index,
  answers,
  preview,
  handleAnswerChange,
  isEssay,
}: {
  question?: {
    id: string;
    question: string;
    maxWordCount?: string;
  };
  index: number;
  answers: ExecutiveSummaryAnswer | undefined;
  preview?: boolean;
  handleAnswerChange?: (index: string, question: string, answer: string) => void;
  isEssay?: boolean;
}) => {
  const wordLimit: number = question?.maxWordCount ? parseInt(question.maxWordCount) * 5 : 1000;
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [focus, setFocus] = useState(false);
  const questionId = question?.id ?? '';
  const questionText = question?.question ?? '';
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const value =
    Object.keys(answers ?? {}).length && answers?.[questionId] ? answers[questionId].answer : '';
  return (
    <div
      className={classNames('summaryBlock', {
        // 'summaryBlock--preview': preview,
        'summaryBlock--focus': focus,
      })}
    >
      <h2 className="summaryBlock__question">{questionText}</h2>
      <div
        className={classNames('summaryBlock__answer', 'editor', {
          'editor--focus': focus,
          'summaryBlock__answer--essay': isEssay,
        })}
      >
        <Editor
          key={`ExecutiveSummary-index-${index.toString()}`}
          apiKey={env.TINY_MCE_API_KEY}
          onInit={(_evt, editor) => (editorRef.current = editor)}
          initialValue={value}
          onBlur={() => {
            if (preview) return;
            if (editorRef.current) {
              const length = editorRef.current.getContent({ format: 'text' }).length;
              if (length >= wordLimit) {
                setLimitExceeded(true);
              } else {
                setLimitExceeded(false);
              }
              handleAnswerChange?.(questionId, questionText, editorRef.current.getContent());
            }
          }}
          init={{
            placeholder: 'Type your answer here...',
            menubar: false,
            statusbar: false,
            inline: true,
            toolbar_mode: 'scrolling',
            toolbar_sticky: true,
            plugins: 'autoresize link lists autolink',
            toolbar: [
              'undo redo | bold italic underline | fontselect fontsizeselect | forecolor backcolor',
              'alignleft aligncenter alignright alignfull | numlist bullist outdent indent',
            ],
            valid_elements: 'p[style],strong,em,span[style],a[href],ul,ol,li',
            valid_styles: {
              '*': 'font-size,font-family,color,text-decoration,text-align',
            },
            min_height: 500,
            height: 500,
          }}
          onFocusIn={() => {
            setFocus(true);
          }}
          onFocusOut={() => {
            setFocus(false);
          }}
        />
        {limitExceeded ? (
          <span className="summaryBlock__error">
            You have exceeded your maximum limit of characters allowed.
          </span>
        ) : null}
      </div>
    </div>
  );
};

const ExecutiveSummaryView = (props: {
  answers: ExecutiveSummaryAnswer | undefined;
  handleAnswerChange?: (id: string, question: string, answer: string) => void;
  options: ExecutiveSummaryOptions;
  preview?: boolean;
}) => {
  const { answers, handleAnswerChange, options, preview } = props;

  return (
    <div className="exs-container">
      <div className="headingBlock">
        <div className="w-100">
          <h1 className="title">
            {options.title
              ? options.title
              : options.questions.length === 1
              ? 'Essay Submission'
              : 'Executive Summary'}
          </h1>
          <div className="d-flex justify-content-between w-100">
            <p className="subtitle">{options.subtitle ?? ''}</p>
            <time className="year">{new Date().getFullYear()}</time>
          </div>
        </div>
      </div>
      {options.questions.map((question, index) => (
        <EditorCanvas
          key={question.id}
          question={question}
          index={index}
          answers={answers}
          preview={preview}
          handleAnswerChange={handleAnswerChange}
          isEssay={options.questions.length === 1}
        />
      ))}
    </div>
  );
};

export default ExecutiveSummaryView;
