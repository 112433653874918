import { useAppSelector } from '@state/redux/store';
import { useEffect, useRef, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoDotFill } from 'react-icons/go';
import { GrAttachment } from 'react-icons/gr';
import { IoIosSend } from 'react-icons/io';
import './ChatBox.scss';

import AudioPlayer from '@components/Projects/TakeProject/Elements/AudioPlayer';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import moment from 'moment';
import ReactPlayer from 'react-player';
import videoIcon from '../../assets/icons/videoIcon.png';
import type { ChatItem } from './TempChat';

export function getMediaType(url: string) {
  if (url.includes('.mp4') || url.includes('vimeo.com')) return 'video';
  if (url.includes('.mp3') || url.includes('.wav')) return 'audio';
  return 'text';
}
interface IAvatar {
  id: string;
  name: string;
  profileImage: string;
  designation: string;
  personality: string;
}
const ChatBox = (props: {
  avatar: IAvatar;
  messages: ChatItem[];
  setMessages?: (messages: ChatItem[]) => void;
  disabled?: boolean;
}) => {
  const { messages = [], avatar, setMessages, disabled = false } = props;
  const [inputValue, setInputValue] = useState('');
  const online = !disabled;

  function handleSubmit(
    e: React.FormEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLTextAreaElement>
  ) {
    e.preventDefault();
    setMessages &&
      setMessages([
        ...messages,
        {
          id: nanoid(),
          message: inputValue.trim(),
          sender: 'user',
          timestamp: new Date().toISOString(),
        },
      ]);
    setInputValue('');
  }
  return (
    <div className="chatbox-container">
      <div className="chatbox-header">
        <div className="chatbox-header-title">
          <img src={avatar.profileImage} alt="Avatar" className="avatar rounded-circle" />
          <div>
            <h1>{avatar.name}</h1>
            <h2>
              {online ? (
                <>
                  <GoDotFill color="#2dce89" size={20} />
                  Online
                </>
              ) : (
                <>
                  <GoDotFill color="#cbcbcb" size={20} />
                  Offline
                </>
              )}
            </h2>
          </div>
        </div>
        <div className="chatbox-header-right">
          <BsThreeDotsVertical size={24} />
        </div>
      </div>

      <div className="chatbox-body">
        <div className="chatbox__body__container">
          <div className="chatbox__body__container__inner">
            {messages.map((message) => {
              return (
                <Message message={message} key={message.id} assistantAvatar={avatar.profileImage} />
              );
            })}
          </div>
        </div>
        <div className="chatbox-footer">
          <form
            className={classNames(
              'chatbot-search-box-container',
              disabled ? 'chatbot-search-box-container-disabled' : null
            )}
          >
            <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center', flex: '1' }}>
              <div>
                <GrAttachment className="chatbot-search-box-fixed-label" size={24} />
              </div>
              <div className="chatbot-input-wrapper">
                <textarea
                  id="chatbot-input"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                  rows={1}
                  placeholder={
                    disabled
                      ? "This chat has ended or locked. You can't send messages."
                      : 'Type a message...'
                  }
                ></textarea>
              </div>
            </div>
            <div>
              <button className="chatbot__sendButton" type="submit" onClick={handleSubmit}>
                <IoIosSend size={32} color="0ea5e9" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ChatBox;

function Message(props: { message: ChatItem; assistantAvatar: string }) {
  const { message, assistantAvatar } = props;
  const user = useAppSelector((state) => state.users);
  const messageBubbleRef = useRef<HTMLDivElement>(null);

  const messageType =
    message.simulationChatItem?.attributes.itemType ?? getMediaType(message.message);
  useEffect(() => {
    if (messageBubbleRef.current) {
      setTimeout(() => {
        messageBubbleRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [message]);

  const renderMedia = () => {
    switch (messageType) {
      case 'video':
        return (
          <div className="chatbox_video_container">
            <div className="chatbox_video_subtitle_container">
              <img className="chatbox_video_subtitle_image" src={videoIcon} alt="video icon" />
              <div className="chatbox_video_subtitle_text_container">
                <div className="chatbox_video_subtitle_text">Video Message</div>
                <div className="chatbox_video_subtitle_subtext">
                  <div className="chatbox_video_subtitle_recorded_container">
                    <GoDotFill color="#cbcbcb" size={20} />
                    <div className="sdf">RECORDED</div>
                  </div>
                  <div>{moment(message.timestamp).format('h:mm A')}</div>
                </div>
              </div>
            </div>
            <ReactPlayer
              url={message.simulationChatItem?.attributes.data.url}
              volume={0.8}
              muted={false}
              playing={false}
              controls
              width="100%"
              height={240}
              config={{
                vimeo: {
                  playerOptions: {
                    title: true,
                    byline: false,
                    portrait: false,
                    vimeoLogo: false,
                  },
                },
              }}
            />
          </div>
        );
      case 'audio':
        return (
          <>
            <AudioPlayer audioUrl={message.simulationChatItem?.attributes.data.url ?? ''} />
            {/* <div className="chatbox__bubble__container__assistant__timestamp">
              {moment(message.timestamp).format('h:mm A')}
            </div> */}
          </>
        );
      case 'text':
        return (
          <>
            <span dangerouslySetInnerHTML={{ __html: message.message }} />
            <div className="chatbox__bubble__container__assistant__timestamp">
              {moment(message.timestamp).format('h:mm A')}
            </div>
          </>
        );
      default:
        return (
          <>
            <span dangerouslySetInnerHTML={{ __html: message.message }} />
            <div className="chatbox__bubble__container__assistant__timestamp">
              {moment(message.timestamp).format('h:mm A')}
            </div>
          </>
        );
    }
  };
  if (message.sender === 'assistant') {
    return (
      <div
        key={message.id}
        className="chatbox__bubble__container chatbox__bubble__container__assistant"
        ref={messageBubbleRef}
      >
        <div className="chatbox__bubble__container__assistant__inner">
          <div>
            <span className="chatbox__bubble__container__assistant__text">{renderMedia()}</span>
          </div>
        </div>
        <img
          src={message.simulationChatItem?.attributes.senderImage ?? assistantAvatar}
          alt="My profile"
          className="chatbox__bubble__container__assistant__img"
          width={144}
          height={144}
        />
      </div>
    );
  }
  if (message.sender === 'user') {
    return (
      <div
        key={message.id}
        className="chatbox__bubble__container chatbox__bubble__container__user"
        ref={messageBubbleRef}
      >
        <div className="chatbox__bubble__container__user__inner">
          <div>
            <span className="chatbox__bubble__container__user__text">
              {message.message}
              <div className="chatbox__bubble__container__user__timestamp">
                {moment(message.timestamp).format('h:mm A')}
              </div>
            </span>
          </div>
        </div>
        <img
          src={message.simulationChatItem?.attributes.senderImage ?? user.user.attributes.avatarUrl}
          alt="My profile"
          className="chatbox__bubble__container__user__img"
          width={144}
          height={144}
        />
      </div>
    );
  }
  return null;
}
