/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import { MdOutlineAttachment } from 'react-icons/md';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import './index.scss';

// import EmailBox from './EmailBox';
import type { SimulationEmail } from '@app/@types/redux/pageElement';
import {
  CardImg,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  Modal,
  UncontrolledDropdown,
} from '@components/UI/Html';
import NoStyleDiv from '@components/UI/Html/NoStyleDiv';
import { getParsedEmailBody, stripHtml } from '@libs/helper';
import logger from '@libs/log';
import { updateSimulationEmailOpened } from '@state/redux/slices/project.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import expEndpoints from '@state/utils/Api';
import classnames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaPlay, FaPlusCircle } from 'react-icons/fa';
import { GrAttachment } from 'react-icons/gr';
import { HiDownload } from 'react-icons/hi';
import { IoCloseSharp } from 'react-icons/io5';
import { LuFileSpreadsheet } from 'react-icons/lu';
import ReactTimeago from 'react-timeago';
import SampleImage from './Sample.png';
import { Viewer, RenderPageProps } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface EmailMessengerProps {
  onClose?: () => void;
  emails: SimulationEmail[];
}
export const ImageWithPlayIcon = () => {
  return (
    <div className="image-container">
      <CardImg className="default__email__image" alt="..." src={SampleImage} top />
      <FaPlay className="play-icon" />
    </div>
  );
};
const EmailMessenger = ({ onClose, emails: propEmails = [] }: EmailMessengerProps) => {
  const emails = [...propEmails];
  const [selectedEmail, setSelectedEmail] = useState<SimulationEmail>(emails[0]);
  const [selectedDropdown, setSelectedDropdown] = useState('Inbox');
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state) => state.projects.currentProject.project.id);
  return (
    <div className="email__main_container">
      <div className="email__main__header">
        <UncontrolledDropdown>
          <DropdownToggle caret color="secondary" className="custom-dropdown-toggle">
            {selectedDropdown}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                setSelectedDropdown('Inbox');
              }}
            >
              Inbox
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setSelectedDropdown('Unread');
              }}
            >
              Unread
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <div className="d-flex align-items-center" id="projectHeaderProgressTooltip">
          {/* {selectedEmail.reply ? (
            <Button
              color="primary"
              onClick={() => {
                setIsSlideOverOpen(true);
              }}
            >
              Reply
            </Button>
          ) : null} */}
          {onClose && <IoCloseSharp size={24} onClick={onClose} />}
        </div>
      </div>
      <>
        {emails.length === 0 ? (
          <div className="email__empty__container">
            <h3>No Emails to show</h3>
            <p>Please check back later or refresh the page.</p>
          </div>
        ) : (
          <div className="email_chat-container">
            <div className="email_left_side">
              <ListGroup flush>
                {emails.map((email) => (
                  <EmailMenu
                    key={email.id}
                    email={email}
                    onClick={() => {
                      setSelectedEmail(email);
                      if (!email.attributes.openedAt) {
                        dispatch(
                          updateSimulationEmailOpened({
                            projectId,
                            simulationEmailId: email.attributes.simulationEmail.id,
                          })
                        ).catch(() => {
                          logger.error('Error updating email opened');
                        });
                      }
                    }}
                    selected={selectedEmail.id === email.id}
                  />
                ))}
              </ListGroup>
            </div>
            <div className="email_right_side">
              <DefaultEmail email={selectedEmail} />
              {/* {isSlideOverOpen && (
            <div className={`slide-over open`}>
              <EmailBox
                setIsSlideOverOpen={() => {
                  setIsSlideOverOpen(false);
                }}
                email={selectedEmail}
              />
            </div>
          )} */}
            </div>
          </div>
        )}
      </>
    </div>
  );
};
interface EmailMenuProps {
  email: SimulationEmail;
  selected: boolean;
  onClick: () => void;
}
export const EmailMenu = ({ email, onClick, selected }: EmailMenuProps) => {
  const handleOnClick = () => {
    onClick();
  };
  const name = useAppSelector((state) => state.users.user.attributes.name);
  const body = getParsedEmailBody(name, email.attributes.simulationEmail.attributes.body);
  const senderName = email.attributes.simulationEmail.attributes.senderName;
  const senderImage = email.attributes.simulationEmail.attributes.senderImage;
  const attachments = email.attributes.simulationEmail.attributes.attachments;
  const emailSentAt = email.attributes.sentAt;
  const subject = email.attributes.simulationEmail.attributes.subject;
  return (
    <div
      className={`email__menu__container ${selected ? 'email__menu__container__selected' : ''}`}
      onClick={handleOnClick}
    >
      <div>
        <img alt="..." className="avatar rounded-circle" src={senderImage} />
      </div>
      <div style={{ width: '100%' }}>
        <div className="email__menu__content">
          <div className="d-flex" style={{ gap: '8px', alignItems: 'center' }}>
            <h1 className="mb-0 text-base">{senderName}</h1>
          </div>
          <div className="d-flex align-items-center text-right text-muted" style={{ gap: '8px' }}>
            {attachments.length > 0 && <MdOutlineAttachment size={20} />}
            <small>
              <ReactTimeago date={emailSentAt ?? ''} />
            </small>
          </div>
        </div>
        <div className="mt-1">
          <h5 className="text-sm mb-0">
            <EllipsisText text={subject} length={45} />
          </h5>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span className="email__menu__message">
            <EllipsisText text={stripHtml(body)} length={30} />
          </span>
          <div className="d-flex align-items-center" style={{ gap: '8px' }}>
            {/* {email.reply && <HiReply size={16} color="gray" />} */}
            {!email.attributes.openedAt ? <Badge className="custom-badge">New</Badge> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailMessenger;

interface TabProps {
  index: number;
  activeTab: number;
  label: string;
  iconClass?: string;
  id: string;
  count: number;
  onClick: (index: number) => void;
}

const Tab: React.FC<TabProps> = ({ index, activeTab, label, onClick, count }) => {
  return (
    <NavItem>
      <NavLink
        aria-selected={activeTab === index}
        className={classnames('mb-sm-3 mb-md-0', { active: activeTab === index })}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          onClick(index);
        }}
        href="#pablo"
        role="tab"
      >
        {label} ({count})
      </NavLink>
    </NavItem>
  );
};

interface TabsProps {
  tabLabels: { label: string; iconClass?: string; id: string; count: number }[];
  children: React.ReactNode[];
}

export const Tabs: React.FC<TabsProps> = ({ tabLabels, children }) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggleNavs = (index: number): void => {
    setActiveTab(index);
  };

  return (
    <>
      <div className="nav-wrapper email_nav_bar">
        <Nav className="nav-fill flex-column flex-md-row" id="tabs-icons-text" pills role="tablist">
          {tabLabels.map((tab, index) => (
            <Tab
              key={index}
              index={index + 1}
              activeTab={activeTab}
              label={tab.label}
              iconClass={tab.iconClass}
              onClick={toggleNavs}
              count={tab.count}
              id={tab.id}
            />
          ))}
        </Nav>
        <div>
          <Button className="btn-icon btn-2" type="button" color="primary">
            <FaPlusCircle />
            <span className="btn-inner--text"> New Conversation</span>
          </Button>
        </div>
      </div>
      <Card className="shadow">
        <CardBody>
          <TabContent activeTab={'tabs' + activeTab.toString()}>
            {children.map((child, index) => (
              <TabPane key={index} tabId={'tabs' + (index + 1).toString()}>
                {child}
              </TabPane>
            ))}
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};

interface DefaultEmailProps {
  email: SimulationEmail | undefined;
}
export const DefaultEmail = ({ email }: DefaultEmailProps) => {
  const toMeEmailAddress = useAppSelector((state) => state.users.user.attributes.email);
  const name = useAppSelector((state) => state.users.user.attributes.name);
  if (!email) {
    return null;
  }
  const senderName = email.attributes.simulationEmail.attributes.senderName;
  const senderEmail = email.attributes.simulationEmail.attributes.senderEmail;
  const senderImage = email.attributes.simulationEmail.attributes.senderImage;
  const attachments = email.attributes.simulationEmail.attributes.attachments;
  const emailSentAt = email.attributes.sentAt;
  const subject = email.attributes.simulationEmail.attributes.subject;
  const body = getParsedEmailBody(name, email.attributes.simulationEmail.attributes.body);
  return (
    <div className="default__email__container">
      <EmailView
        subject={subject}
        body={body}
        senderName={senderName}
        senderEmail={senderEmail}
        senderImage={senderImage}
        attachments={attachments}
        emailSentAt={emailSentAt ?? ''}
        toMeEmailAddress={toMeEmailAddress}
      />
    </div>
  );
};

export function EmailView(props: {
  subject: string;
  body: string;
  senderName: string;
  senderEmail: string;
  senderImage: string;
  attachments: { url: string; name: string }[];
  emailSentAt: string;
  toMeEmailAddress: string;
  replyToSimulationEmailId?: string;
}) {
  const {
    subject,
    body,
    senderName,
    senderEmail,
    senderImage,
    attachments,
    emailSentAt,
    toMeEmailAddress,
    replyToSimulationEmailId,
  } = props;

  const [simulationEmail, setSimulationEmail] = useState<SimulationEmail | undefined>(undefined);
  const projectId = useAppSelector((state) => state.projects.currentProject.project.id);
  const name = useAppSelector((state) => state.users.user.attributes.name);
  useEffect(() => {
    if (replyToSimulationEmailId) {
      expEndpoints
        .getSimulationEmailById(projectId, replyToSimulationEmailId)
        .then((res) => {
          setSimulationEmail(res.data.data);
        })
        .catch(() => {
          logger.error('Failed to fetch simulation email');
        });
    }
  }, [replyToSimulationEmailId]);

  const replyEmailSentAt = simulationEmail?.attributes.sentAt;
  const replySenderEmail = simulationEmail?.attributes.simulationEmail.attributes.senderEmail;
  const replyAttachments = simulationEmail?.attributes.simulationEmail.attributes.attachments;
  const replyBody = getParsedEmailBody(
    name,
    simulationEmail?.attributes.simulationEmail.attributes.body ?? ''
  );
  return (
    <Card className="default__email__card">
      <CardTitle className="mb-3" tag="h2">
        {subject}
      </CardTitle>
      <div className="default__email__header">
        <h2 className="default__email__header__title">
          <img alt="..." className="avatar rounded-circle" src={senderImage} />
          <div>
            <h1 className="mb-0 text-sm">
              {senderName}
              <small className="ml-2 default__email__header__subtext">{senderEmail}</small>
            </h1>
            <small className="default__email__header__toEmailAddress">to {toMeEmailAddress}</small>
          </div>
        </h2>
        <div className="default__email__header__actions">
          {attachments.length > 0 && <GrAttachment size={20} color="gray" />}
          <small className="default__email__header__subtext">
            <ReactTimeago date={emailSentAt} />
          </small>
        </div>
      </div>

      <NoStyleDiv>{body}</NoStyleDiv>
      {attachments.length > 0 ? <Attachments attachments={attachments} /> : null}
      {replySenderEmail && replyAttachments && replyEmailSentAt && replyBody ? (
        <div className="fixed-text-preview">
          On {moment(replyEmailSentAt).format('MMMM Do YYYY, h:mm:ss a')},
          <span style={{ margin: '0 6px', fontWeight: 700 }}>{replySenderEmail}</span>
          wrote:
          <br />
          <div className="pl-4 ml-2" style={{ borderLeft: 'solid 1px #d8d8d8' }}>
            <NoStyleDiv>{replyBody}</NoStyleDiv>
            {replyAttachments.length && replyAttachments.length > 0 ? (
              <Attachments attachments={replyAttachments} />
            ) : null}
          </div>
        </div>
      ) : null}
    </Card>
  );
}
export function Attachments({ attachments }: { attachments: { url: string; name: string }[] }) {
  const [pdfModal, setPdfModal] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const renderPage = (props: RenderPageProps) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: 'none' }}>{props.textLayer.children}</div>
        {props.annotationLayer.children}
      </>
    );
  };
  return (
    <div className="email__attachments">
      <h5 className="email__attachments__title">Attachments ({attachments.length})</h5>
      <div className="emailPage__attachments__container">
        {attachments.map((attachment, index) => {
          return (
            <div key={index} className="email__attachments__item">
              <div rel="noreferrer" className="email__attachments__link">
                <div className="email__attachments__content">
                  <LuFileSpreadsheet className="email__attachments__icon" size={32} />
                  <EllipsisText className="email_text" text={attachment.name} length={12} />
                </div>
                <HiDownload
                  onClick={() => {
                    if (attachment.url.endsWith('.pdf')) {
                      setPdfModal(true);
                      setAttachmentUrl(attachment.url);
                    } else {
                      window.open(attachment.url, '_blank');
                    }
                  }}
                  className="email__attachments__icon--end"
                  size={26}
                />
              </div>
            </div>
          );
        })}
      </div>

      <Modal
        size="lg"
        isOpen={pdfModal}
        toggle={() => {
          setPdfModal(false);
        }}
        className=""
        showCloseButton={true}
      >
        <div style={{ height: '85vh' }}>
          <Viewer fileUrl={attachmentUrl} renderPage={renderPage} />
        </div>
      </Modal>
    </div>
  );
}
